import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProcurementMap from "../sub-map/ProcurementMap";
import { useRef } from "react";
import axios from "axios";
import styled from "styled-components";

function ProcurementDetail() {
  let navigate = useNavigate();

  // id is procurement
  // type is 혁신 등 외 4개
  let { id, procurement, type } = useParams();

  const [category, setCategory] = useState([]);
  const [detailInfo, setDetailInfo] = useState([]);
  const [guideList, setGuideList] = useState([]);
  const [table, setTable] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);

  const [tableRow, setTableRow] = useState([]);
  const [guideListTypeCount, setGuideListTypeCount] = useState([]);

  // toggleTab
  let [oldCategory, setOldCategory] = useState();
  let [isCategroyChange, setIsCategoryChange] = useState();

  // 공공조달 디테일 카테고리 리스트
  let categoryList = [
    { id: 0, title: "혁신장터" },
    { id: 1, title: "벤처나라" },
    { id: 2, title: "학교장터" },
    { id: 3, title: "동반성장물" },
    { id: 4, title: "우수조달" },
  ];

  //
  const liRef = useRef(null);

  //
  function toggleTab(e, typeNumber) {
    if (e) {
      if (oldCategory) {
        oldCategory.target.classList.remove("now");
      } else {
        if (liRef.current) {
          liRef.current.classList.remove("now");
        }
      }

      e.target.classList.add("now");
      setOldCategory(e);
      setIsCategoryChange(typeNumber);

      navigate(
        "/Procurement/ProcurementDetail/" +
          procurement +
          "/" +
          id +
          "/" +
          typeNumber
      );
    }
  }

  // 파일다운=================================================================
  function downloadImage(url) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        if (
          navigator.userAgent.match(
            /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i
          )
        ) {
          document.body.innerHTML = "";
          if (navigator.userAgent.match(/iPhone|iPad/i)) {
            if (navigator.userAgent.match(/NAVER/i)) {
              var icox = window.open(
                url,
                "",
                "width=0, height=0, left=0, top=0,  scrollbars=no,status=no,toolbar=no,menubar=no,resizeable=no,location=no"
              );
              icox.addEventListener("beforeunload", (event) => {
                event.preventDefault();
                event.returnValue = "";
              });
              window.location.reload();
              return;
            }
            var icox = window.open(
              url,
              "",
              "width=0, height=0, left=0, top=0,  scrollbars=no,status=no,toolbar=no,menubar=no,resizeable=no,location=no"
            );
            setTimeout(function () {
              icox.close();
              window.location.reload();
            }, 100);
          }
        }
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.download = url.replace(/^.*[\\\/]/, "");
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  // Axios Get Data
  const getProcurementDetailData = () => {
    // id, type
    let url = "https://api.bio3s.com/api/productProdcurementDetail";

    axios
      .get(url, {
        params: {
          id: id,
          type: type,
          procurementId: procurement,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          console.log(res.data.data);
          console.log(">>>>>>" + categoryList[type]?.id);

          if (data.detailInfo) {
            setCategory(data.category || []);
            setDetailInfo(data.detailInfo || []);
            setGuideList(data.guideList || []);
            setTable(data.table || []);
            setTableHeader(data.tableHeader || []);

            const typeCount = {
              zeroSection: { count: 0 },
              firstSection: { count: 0 },
              secondSection: { count: 0 },
              sixSection: { count: 0 },
              sevenSection: { count: 0 },
            };

            // 가이드 리스트 중, 데이터가 없는 섹션의 경우
            // 섹션을 노출시키면 안되므로, 섹션 별 데이터 카운팅
            if (data.guideList) {
              data.guideList.map((item, index) => {
                switch (item.type) {
                  case 0:
                    typeCount.zeroSection.count++;
                    break;

                  case 1:
                    typeCount.firstSection.count++;
                    break;

                  case 2:
                    typeCount.secondSection.count++;
                    break;

                  case 6:
                    typeCount.sixSection.count++;
                    break;

                  case 7:
                    typeCount.sevenSection.count++;
                    break;

                  default:
                    break;
                }
              });
            }

            setGuideListTypeCount(typeCount);

            // 데이터를 불러온 뒤, 렌더링 작업 수행
            tableRendering(data.table);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 테이블 데이터 렌더링 함수
  // Axios Get 함수 내에서 함수 실행됨
  const tableRendering = (table) => {
    const result = [];

    // 데이터 내, row 개수 확인
    if (!table[table.length - 1]) {
      setTableRow([]);
      return;
    }

    let tableRowLastCount = table[table.length - 1].row + 1;

    // row = 줄
    // 줄 단위 for
    // row 내 column 데이터 노출을 위해 map
    for (let row = 1; row < tableRowLastCount; row++) {
      let rowData = (
        <tr key={row}>
          {table.map((item, index) => {
            if (row === item.row) {
              if (item.type == 1) {
                return (
                  <td key={index}>
                    <button onClick={() => window.open(item.content, "_blank")}>
                      제품 링크
                    </button>
                  </td>
                );
              } else {
                return <td key={index}>{item.content}</td>;
              }
            }
          })}
        </tr>
      );

      result.push(rowData);
    }

    setTableRow(result);
  };

  //
  useEffect(() => {
    getProcurementDetailData();
  }, [isCategroyChange]);

  //
  return (
    <div className="Procurement ProcurementDetail">
      <ProcurementMap tag2={"혁신장터"}></ProcurementMap>
      <div className="sub-con">
        {/* Detail Info */}
        <div className="section1">
          <div className="container">
            <ul className="tab-btn">
              {category.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={(e) => {
                      toggleTab(e, categoryList[item.type]?.id);
                    }}
                    className={type == item.type ? "now" : ""}
                  >
                    {categoryList[item.type]?.title}
                  </li>
                );
              })}
            </ul>
          </div>

          {/* Detail Info */}

          <img
            src={require("../../images/product_bg.png")}
            alt=""
            className="st1-bg"
          />

          <div className="container">
            <div className="img-wrap">
              <div className="img-wrap2">
                <img src={detailInfo.fileAddr} alt="" />
              </div>
            </div>

            <div className="con-box">
              <span className="classify1">
                {categoryList[detailInfo.type]?.title || ""}
              </span>
              <div className="txt-wrap">
                <p className="txt-top">{detailInfo.title}</p>
                <div className="txt-ct">
                  <span>
                    <i className="xi-shop"></i>
                  </span>
                  <p>{detailInfo.tags}</p>
                </div>
                <p className="txt-bt">{detailInfo.short_content}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Table Data */}
        <div className="section2">
          {tableRow.length > 0 ? (
            <div className="container">
              <div className="scroll-wrap">
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        {tableHeader.map((item, index) => {
                          return <th key={index}>{item.content}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {/*  */}
                      {tableRow}
                    </tbody>
                  </table>
                </div>
              </div>
              <p className="caution-p">{detailInfo.deliverGuide}</p>
              <div className="img-wrap">
                {guideList.map((item, index) => {
                  // type = 0 is 구매 방법
                  if (item.type == 6) return <img src={item.fileAddr} alt="" />;
                })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* Guide List */}
        <div className="section3">
          <div className="container">
            {guideListTypeCount ? (
              guideListTypeCount.firstSection ? (
                guideListTypeCount.firstSection.count > 0 ? (
                  <div className="information-wrap">
                    <h3 className="h3-st1">구매방법</h3>
                    <ul className="txt-li">
                      {guideList.map((item, index) => {
                        // type = 0 is 구매 방법
                        if (item.type == 0)
                          return <li key={index}>{item.content}</li>;
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {guideListTypeCount ? (
              guideListTypeCount.firstSection ? (
                guideListTypeCount.firstSection.count > 0 ? (
                  <div className="information-wrap">
                    <h3 className="h3-st1">계약서류</h3>
                    {guideList.map((item, index) => {
                      // type = 1 is 구매 옵션
                      if (item.type == 7) {
                        return (
                          <a
                            key={index}
                            className="download-btn"
                            onClick={() => downloadImage(item.fileAddr)}
                          >
                            다운로드 <i className="xi-download"></i>
                          </a>
                        );
                      }
                    })}
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {guideListTypeCount ? (
              guideListTypeCount.firstSection ? (
                guideListTypeCount.firstSection.count > 0 ? (
                  <div className="information-wrap">
                    <h3 className="h3-st1">구매옵션 (VAT 포함)</h3>
                    <ul className="txt-li">
                      {guideList.map((item, index) => {
                        // type = 1 is 구매 옵션
                        if (item.type == 1)
                          return <li key={index}>{item.content}</li>;
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {categoryList[type]?.id !== 4 && guideListTypeCount ? (
              guideListTypeCount.firstSection ? (
                guideListTypeCount.firstSection.count > 0 ? (
                  <div className="information-wrap">
                    <h3 className="h3-st1">홍보용 라벨 부착 예시</h3>
                    <ul className="txt-li">
                      {guideList.map((item, index) => {
                        // type = 2 is 홍보용 라벨 부착 예시
                        if (item.type == 2)
                          return <li key={index}>{item.content}</li>;
                      })}
                    </ul>
                    <ul className="img-li">
                      {guideList.map((item, index) => {
                        // type = 2 is 홍보용 라벨 부착 예시
                        if (
                          item.type == 3 ||
                          item.type == 4 ||
                          item.type == 5
                        ) {
                          if (item.fileAddr) {
                            return (
                              <li key={index}>
                                <span>{item.content}</span>
                                <div className="img-wrap">
                                  <img src={item.fileAddr} alt="" />
                                </div>
                              </li>
                            );
                          }
                        }
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcurementDetail;
