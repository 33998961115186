import { Outlet } from 'react-router-dom';
import Header from './HeaderEN';
import Footer from './FooterEN'

function EnMould() {
    return (
        <>
            <Header/>
            <Outlet/>
            <Footer/>
        </>
    )
}

export default EnMould;