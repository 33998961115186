import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContactMap from "../sub-map/ContactMap";

function Directions() {
  useEffect(() => {
    const { naver } = window;
    var mapOptions = {
      center: new naver.maps.LatLng(35.2314452, 126.863507),
      zoom: 30,
    };
    var map = new naver.maps.Map("map", mapOptions);

    return () => {
      window.location.reload();
    };
  }, []);

  return (
    <div className="Contact Directions">
      <ContactMap tag2={"오시는 길"}></ContactMap>
      <div className="sub-con">
        <div className="section1">
          <div className="container">
            <h3 className="h3-st2">오시는 길</h3>
            <div className="map-wrap">
              <div id="map"></div>
            </div>
            <ul className="flex-wrap">
              <li>
                <span className="icon">
                  <i className="xi-call"></i>
                </span>
                <h4>문의전화</h4>
                <p>1899-5397</p>
              </li>
              <li>
                <span className="icon">
                  <i className="xi-maker"></i>
                </span>
                <h4>본사주소</h4>
                <p>광주광역시 북구 용봉로 1 용비빌딩 3층</p>
              </li>
              <li>
                <span className="icon">
                  <i className="xi-mail"></i>
                </span>
                <h4>이메일</h4>
                <p>contact@bio3s.com</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <h3 className="h3-st1">소재지</h3>
            <ul className="flex-wrap">
              <li>
                <div className="img-wrap">
                  <img src={require("../../images/com-icon-01.png")} alt="" />
                </div>
                <div className="txt-wrap">
                  <p>본사 및 바이오신소재 제조 시설</p>
                  <p>광주광역시 북구 용봉로 77 농업전문창업보육센터 102호</p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://map.naver.com/p/entry/place/1214139192?placePath=%2Fhome&c=15.00,0,0,0,dh"
                    )
                  }
                >
                  지도보기
                </button>
              </li>
              <li>
                <div className="img-wrap">
                  <img src={require("../../images/com-icon-03.png")} alt="" />
                </div>
                <div className="txt-wrap">
                  <p>의료기기 판매시설</p>
                  <p>광주광역시 북구 용봉로 1 용비빌딩 3층</p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://map.naver.com/v5/search/%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C%2062%EB%B2%88%EA%B8%B8%2030/address/14122376.212750759,4195377.973604299,%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C62%EB%B2%88%EA%B8%B8%2030,new?c=14122348.7057046,4195378.6413890,19,0,0,0,dh&isCorrectAnswer=true",
                      "_blank"
                    )
                  }
                >
                  지도보기
                </button>
              </li>
              <li>
                <div className="img-wrap">
                  <img src={require("../../images/com-icon-05.png")} alt="" />
                </div>
                <div className="txt-wrap">
                  <p>화장품 제조 시설</p>
                  <p>광주광역시 북구 첨단벤처소로 62번길 30</p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://map.naver.com/p/search/%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C%2062%EB%B2%88%EA%B8%B8%2030/address/14122376.2127508,4195377.9736043,%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C62%EB%B2%88%EA%B8%B8%2030,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
                      "_blank"
                    )
                  }
                >
                  지도보기
                </button>
              </li>
              <li>
                <div className="img-wrap">
                  <img src={require("../../images/com-icon-04.png")} alt="" />
                </div>
                <div className="txt-wrap">
                  <p>기업부설연구소</p>
                  <p className="oth">
                    정) 광주광역시 북구 첨단벤처소로 62번길 30
                  </p>
                  <p>부) 대전광역시 유성구 과학로 한국기초과학지원연구원</p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://map.naver.com/p/search/%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C%2062%EB%B2%88%EA%B8%B8%2030/address/14122376.2127508,4195377.9736043,%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C62%EB%B2%88%EA%B8%B8%2030,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
                      "_blank"
                    )
                  }
                >
                  정) 지도보기
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Directions;
