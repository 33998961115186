import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AboutUsMap from "../sub-map/AboutUsMap";

function Outline() {
  return (
    <div className="AboutUs introduce outline">
      <AboutUsMap tag2={"회사소개"} tag3={"기업 개요"}></AboutUsMap>
      <div className="mb-sub-menu container">
        <ul>
          <li>
            <Link to={"/AboutUs"}>BIO3S 목표</Link>
          </li>
          <li className="now">
            <Link to={"/AboutUs/outline"}>기업개요</Link>
          </li>
          <li>
            <Link to={"/AboutUs/management"}>경영진 소개</Link>
          </li>
          <li>
            <Link to={"/AboutUs/Cooperation"}>회사조직 및 협력기관</Link>
          </li>
        </ul>
      </div>
      <div className="sub-con">
        <div className="section1">
          <div className="container">
            <div className="flex-wrap">
              <div className="img-wrap">
                <img
                  className="pc"
                  src={require("../../images/BUILDINGS.png")}
                  alt=""
                />
                <img
                  className="mb"
                  src={require("../../images/mb-img/BUILDINGS.png")}
                  alt=""
                />
              </div>
              <div className="con-box">
                <div>
                  <h3 className="scd">기업 개요</h3>
                  <div className="list-wrap">
                    <ul className="left-ul">
                      <li>
                        <span>회 사 명</span>
                        <p>㈜바이오쓰리에스</p>
                      </li>
                      <li>
                        <span>대 표 자</span>
                        <p>김 두 운</p>
                      </li>
                      <li>
                        <span>설 립 일</span>
                        <p>2016년 10월 18일</p>
                      </li>
                    </ul>
                    <ul className="right-ul">
                      <li>
                        <span className="letter">법인등록번호</span>
                        <p>200111-0475339</p>
                      </li>
                      <li>
                        <span className="letter sm">사업자등록번호</span>
                        <p>372-87-00681</p>
                      </li>
                      <li>
                        <span className="letter">대표전화</span>
                        <p>1899-5397</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <h3 className="h3-st2">소재지</h3>
            <ul>
              <li>
                <img src={require("../../images/com-icon-01.png")} alt="" />
                <p className="txt-top">본사 및 바이오신소재 제조 시설</p>
                <p className="txt-ct">
                  광주광역시 북구 용봉로 77
                  <br />
                  농업전문창업보육센터 102호
                </p>
                <button
                  onClick={() =>
                    window.open(
                      "https://map.naver.com/p/search/%EC%A0%84%EB%82%A8%EB%8C%80%ED%95%99%EA%B5%90%EB%86%8D%EC%97%85%EC%A0%84%EB%AC%B8%EC%B0%BD%EC%97%85%EB%B3%B4%EC%9C%A1%EC%84%BC%ED%84%B0?c=15.00,0,0,0,dh",
                      "_blank"
                    )
                  }
                >
                  지도보기
                </button>
              </li>
              <li>
                <img src={require("../../images/com-icon-03.png")} alt="" />
                <p className="txt-top">화장품 제조 시설</p>
                <p className="txt-ct">
                  광주광역시 북구
                  <br />
                  첨단벤처소로 62번길 30
                </p>
                <button
                  onClick={() =>
                    window.open(
                      "https://map.naver.com/v5/search/%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C%2062%EB%B2%88%EA%B8%B8%2030/address/14122376.212750759,4195377.973604299,%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C62%EB%B2%88%EA%B8%B8%2030,new?c=14122348.7057046,4195378.6413890,19,0,0,0,dh&isCorrectAnswer=true",
                      "_blank"
                    )
                  }
                >
                  지도보기
                </button>
              </li>
              <li>
                <img src={require("../../images/com-icon-05.png")} alt="" />
                <p className="txt-top">의료기기 판매업</p>
                <p className="txt-ct">
                  광주광역시 북구
                  <br />
                  용봉로 1 용비빌딩 3층
                </p>
                <button
                  onClick={() =>
                    window.open(
                      "https://map.naver.com/p/search/%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%9A%A9%EB%B4%89%EB%A1%9C%201%20%EC%9A%A9%EB%B9%84%EB%B9%8C%EB%94%A9/place/1502240149?c=15.00,0,0,0,dh&isCorrectAnswer=true",
                      "_blank"
                    )
                  }
                >
                  지도보기
                </button>
              </li>
              <li>
                <img src={require("../../images/com-icon-04.png")} alt="" />
                <p className="txt-top">연구소</p>
                <p className="txt-ctl">
                  정) 광주광역시 북구
                  <br />
                  첨단벤처소로 62번길 30
                </p>
                <p className="txt-ctl">
                  부) 대전광역시 유성구 과학로
                  <br />
                  한국기초과학지원연구원
                </p>
                <div className="buttonSection">
                  <button
                    onClick={() =>
                      window.open(
                        "https://map.naver.com/p/search/%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C%2062%EB%B2%88%EA%B8%B8%2030/address/14122376.2127508,4195377.9736043,%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C62%EB%B2%88%EA%B8%B8%2030,new?c=16.33,0,0,0,dh&isCorrectAnswer=true",
                        "_blank"
                      )
                    }
                  >
                    정) 지도보기
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://map.naver.com/v5/search/%EB%8C%80%EC%A0%84%EA%B4%91%EC%97%AD%EC%8B%9C%20%EC%9C%A0%EC%84%B1%EA%B5%AC%20%EA%B3%BC%ED%95%99%EB%A1%9C%20%ED%95%9C%EA%B5%AD%EA%B8%B0%EC%B4%88%EA%B3%BC%ED%95%99%EC%A7%80%EC%9B%90%EC%97%B0%EA%B5%AC%EC%9B%90/place/13188347?c=14176538.2879822,4351971.8024034,15,0,0,0,dh",
                        "_blank"
                      )
                    }
                  >
                    부) 지도보기
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="section3">
          <div className="container">
            <h3 className="h3-st1">사업비전</h3>
            <div className="flax-wrap">
              <div className="flax-box">
                <img src={require("../../images/com-le.jpg")} alt="" />
                <h4 className="scd">
                  현재 사업<span>2016 ~</span>
                </h4>
                <ul>
                  <li>위생용품 사업</li>
                  <li>바이오소재 사업</li>
                  <li>의약외품 사업</li>
                </ul>
              </div>
              <div className="flax-box">
                <img src={require("../../images/com-ri.jpg")} alt="" />
                <h4 className="scd">
                  차세대 사업<span>2022 ~</span>
                </h4>
                <ul>
                  <li>구강검체채취 목적의 신의료기기의 등록</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section4">
          <div className="container">
            <div className="img-wrap">
              <img
                className="pc"
                src={require("../../images/model.png")}
                alt=""
              />
              <img
                className="mb"
                src={require("../../images/mb-img/model-m.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="section5">
          <div className="container">
            <div className="img-wrap">
              <img
                className="pc"
                src={require("../../images/core-bis.png")}
                alt=""
              />
              <img
                className="mb"
                src={require("../../images/mb-img/core-biz-m.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Outline;
