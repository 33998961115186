import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ResearchMap from "../sub-map/ResearchMap";
import { useRef } from "react";
// API
import axios from "axios";

function Patent() {
  let [tab, setTab] = useState(0);
  let [data, setData] = useState();
  let [newData, setNewData] = useState([]);
  // toggleTab
  let [oldCategory, setOldCategory] = useState();
  const liRef = useRef(null);
  let [title, setTitle] = useState("지적재산권 국내 출원");

  function changeData(e) {
    if (data != null) {
      let copy = data.patents;
      let copy2 = [];
      copy.map((item, i) => {
        if (item.type == e) {
          copy2.push(copy[i]);
        }
      });
      setNewData(copy2);
    }
  }

  function toggleTab(e) {
    if (e) {
      if (oldCategory) {
        oldCategory.target.classList.remove("now");
      } else {
        if (liRef.current) {
          liRef.current.classList.remove("now");
        }
      }

      e.target.classList.add("now");
      setOldCategory(e);
    }
  }

  useEffect(() => {
    axios
      .get("https://api.bio3s.com/api/patent")
      .then((res) => {
        setData(res.data.data);
        console.log(data);
      })
      .catch(() => {
        console.log("실패");
      });
  }, []);

  useEffect(() => {
    changeData(0);
  }, [data]);

  return (
    <div className="research result Patent">
      <ResearchMap tag2={"연구성과"} tag3={"특허목록"}></ResearchMap>
      <div className="sub-con">
        <div className="container">
          <ul className="tab-btn">
            <li
              ref={liRef}
              onClick={(e) => {
                setTab(0);
                changeData(0);
                toggleTab(e);
                setTitle("지적재산권 국내 출원");
              }}
              className="now"
            >
              지적재산권 국내 출원
            </li>
            <li
              onClick={(e) => {
                setTab(0);
                changeData(1);
                toggleTab(e);
                setTitle("지적재산권 국내 등록");
              }}
            >
              지적재산권 국내 등록
            </li>
            <li
              onClick={(e) => {
                setTab(0);
                changeData(2);
                toggleTab(e);
                setTitle("지적재산권 해외 출원");
              }}
            >
              지적재산권 해외 출원
            </li>
            <li
              onClick={(e) => {
                setTab(0);
                changeData(3);
                toggleTab(e);
                setTitle("지식재산권 해외 등록");
              }}
            >
              지식재산권 해외 등록
            </li>
            <li
              onClick={(e) => {
                setTab(1);
                toggleTab(e);
              }}
            >
              인증현황
            </li>
          </ul>
          <TabContent
            tab={tab}
            data={data}
            data2={newData}
            title={title}
          ></TabContent>
        </div>
      </div>
    </div>
  );
}

function TabContent({ tab, data, data2, title }) {
  let [addNumber, setAddNumber] = useState(10);
  let [btn, setBtn] = useState("");
  let [btn2, setBtn2] = useState("");
  const faqList = useRef([]);
  const [oldFaqList, setOldFaqList] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setAddNumber(10);
    setBtn("");
    setBtn2("");
  }, [tab]);

  useEffect(() => {
    if (data != null) {
      const count = Object.keys(data.certificates).length;
      if (count <= addNumber) {
        setBtn(" dp-none");
      }
    }

    if (data2 != null) {
      const count = Object.keys(data2).length;
      if (count <= addNumber) {
        setBtn2(" dp-none");
      }
    }
  });

  function toggleClass(i) {
    if (oldFaqList) {
      oldFaqList.classList.remove("open");
    }

    if (!isOpen) {
      faqList.current[i].classList.add("open");
      setIsOpen(true);
    } else {
      if (oldFaqList == faqList.current[i]) {
        faqList.current[i].classList.remove("open");
        setIsOpen(false);
      } else {
        faqList.current[i].classList.add("open");
        setIsOpen(true);
      }
    }

    setOldFaqList(faqList.current[i]);
  }

  return (
    <>
      {tab == 0 ? (
        <div>
          <h3 className="h3-st1">{title}</h3>
          <ul className="Patent-list">
            {data2.map((item, i) => {
              return (
                <li key={i}>
                  <div
                    onClick={() => {
                      toggleClass(i);
                    }}
                    className="title-wrap"
                  >
                    <div className="img-wrap"></div>
                    <div className="txt-wrap">
                      <p className="num">{item.number}</p>
                      <p className="title">{item.title}</p>
                    </div>
                    <i className="xi-angle-down-thin"></i>
                  </div>
                  <div className="toggle-wrap">
                    <div
                      ref={(e) => (faqList.current[i] = e)}
                      className="Patent-con"
                    >
                      <p>
                        출원인 | <span>{item.writer}</span>
                      </p>
                      <p>
                        출원일 / 등록일 | <span>{item.register_date}</span>
                      </p>
                      <p>
                        출원/등록 (국가) | <span>{item.country}</span>
                      </p>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <button
            className={"btn-st1" + btn2}
            onClick={() => {
              setAddNumber(addNumber + 10);
            }}
          >
            더보기
          </button>
        </div>
      ) : null}
      {tab == 1 ? (
        <div>
          <h3 className="h3-st1">인증 및 등록 현황</h3>
          <div className="scroll-wrap">
            <div className="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>일자</th>
                    <th>진행현황</th>
                    <th>인증번호</th>
                    <th>인증 및 등록내용</th>
                  </tr>
                </thead>
                <tbody>
                  {data ? (
                    data.certificates.map((item, i) => {
                      if (i < addNumber) {
                        return (
                          <tr key={i}>
                            <td>{item.type}</td>
                            <td>{item.register_date}</td>
                            <td>{item.doc_status}</td>
                            <td className="sm-ft">{item.number}</td>
                            <td className="sm-ft">{item.content}</td>
                          </tr>
                        );
                      }
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <button
            className={"btn-st1" + btn}
            onClick={() => {
              setAddNumber(addNumber + 10);
            }}
          >
            더보기
          </button>
        </div>
      ) : null}
    </>
  );
}
export default Patent;
