import { useEffect } from 'react';
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';

function Footer({ftCss}) {

    useEffect(()=>{
        document.getElementById("upBtn").addEventListener("click",function () {
            window.scrollTo(0,0);
        })
    },[])
    return (
        <div className={"footer " + ftCss}>
            <h1 className='logo'></h1>
            <div className='ft-content'>
                <ul className='ft-le'>
                    <li>Addr : 30, Cheomdan Venturesoro 62Beongil, Bukgu, Gwangju, Republic of Korea</li>
                    <li>Tel :  1899-5397</li>
                    <li>E-mail : contact@bio3s.com</li>
                </ul>
                <div className='ft-ri'>
                    <ul>
                        <li>Personal information processing policy</li>
                        <li>Company Profile</li>
                    </ul>
                    <p>Copyright  BIO3S  All rights reserved.</p>
                </div>
            </div>
            <button id='upBtn'><i className='xi-long-arrow-up'></i></button>
        </div>
    )
}
export default Footer;