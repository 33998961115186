import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import PRMap from "../sub-map/PRMap";
import "swiper/css";
import { useParams } from "react-router-dom";
import Parser from "html-react-parser";
// API
import axios from "axios";

function NewsDetail() {
  let navigate = useNavigate();
  let { id } = useParams();
  console.log(id);
  let [data, setData] = useState();

  useEffect(() => {
    axios
      .get("https://api.bio3s.com/api/board/detail?id=" + id + "&type=0")
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
      })
      .catch(() => {
        console.log("실패");
      });
  }, [id]);

  // 파일다운=================================================================
  function downloadImage(url) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        if (
          navigator.userAgent.match(
            /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i
          )
        ) {
          document.body.innerHTML = "";
          if (navigator.userAgent.match(/iPhone|iPad/i)) {
            if (navigator.userAgent.match(/NAVER/i)) {
              var icox = window.open(
                url,
                "",
                "width=0, height=0, left=0, top=0,  scrollbars=no,status=no,toolbar=no,menubar=no,resizeable=no,location=no"
              );
              icox.addEventListener("beforeunload", (event) => {
                event.preventDefault();
                event.returnValue = "";
              });
              window.location.reload();
              return;
            }
            var icox = window.open(
              url,
              "",
              "width=0, height=0, left=0, top=0,  scrollbars=no,status=no,toolbar=no,menubar=no,resizeable=no,location=no"
            );
            setTimeout(function () {
              icox.close();
              window.location.reload();
            }, 100);
          }
        }
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.download = data.files[0].origin_filename;
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  return (
    <div className="PR NewsDetail">
      <PRMap tag2={"회사소식"}></PRMap>
      <div className="sub-con">
        <div className="container">
          <div className="title-wrap">
            <h4>{data ? data.detail[0].title : null}</h4>
          </div>
          <div className="date-wrap">
            <p>
              조회수 : <span>{data ? data.detail[0].count : null}</span>
            </p>
            <p>
              작성일 : <span>2022-07-01</span>
            </p>
          </div>
          <div className="con-wrap">
            {data ? Parser(data.detail[0].content) : null}
          </div>
          <div className="attachedFile">
            <div className="attachedFile-top">
              <i className="xi-paperclip"></i>
              <span>첨부파일</span>
            </div>
            <ul className="file-list">
              {data
                ? data.files.map((item, i) => {
                    return (
                      <li key={i}>
                        <i className="xi-download"></i>
                        <a onClick={() => downloadImage(item.fileAddr)}>
                          {item.origin_filename}
                        </a>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
          <button
            className="inventory-btn"
            onClick={() => navigate("/PR/News")}
          >
            목록
          </button>
          <div className="turn-wrap">
            <div>
              <span>
                이전글 <i className="xi-angle-up-min"></i>
              </span>
              {data ? (
                data.previous ? (
                  <Link
                    to={"/PR/NewsDetail/" + (data ? data.previous.id : null)}
                  >
                    {data ? data.previous.title : null}
                  </Link>
                ) : (
                  <Link to={"/PR/NewsDetail/" + id}> 이전글이 없습니다</Link>
                )
              ) : null}
            </div>
            <div>
              <span>
                다음글 <i className="xi-angle-down-min"></i>
              </span>
              {data ? (
                data.next ? (
                  <Link to={"/PR/NewsDetail/" + (data ? data.next.id : null)}>
                    {data ? data.next.title : null}
                  </Link>
                ) : (
                  <Link to={"/PR/NewsDetail/" + id}> 다음글이 없습니다</Link>
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetail;
