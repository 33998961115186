import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Footer from '../components/Footer';
import Parser from 'html-react-parser'
// Slick
import Slider1 from "react-slick";
import Slider2 from "react-slick";

// Swiper
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";

// fullpage
import $ from 'jquery';
import 'fullpage.js/vendors/scrolloverflow';
import 'fullpage.js';

// API
import axios from 'axios';
import { useCookies } from 'react-cookie';

function Main() {

    let [ftCss, setFtCss] = useState("section global-footer-container fp-auto-height");
    let [slideControl, setSlideControl] = useState();
    let [playing, setPlaying] = useState(true);
    let navigate = useNavigate();
    let [data, setData] = useState();
    let [popCs, setPopCs] = useState("");

    // INQUIRY
    let [content, setContent] = useState();
    let [optionItem, setOptionItem] = useState();
    let [input1, setInput1] = useState();
    let [input2, setInput2] = useState();
    let [input3, setInput3] = useState();
    let [input4, setInput4] = useState();
    let [agreement, setAgreement] = useState();
    let [popUp, setPopUp] = useState("");
    let [warning, setWarning] = useState("");

    useEffect(() => {

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, [])

    let [ windowHeight, setWindowHeight ] = useState(0);
    
    let usePopupRef = useRef(null);

    const handleResize = () => {

        setWindowHeight(window.innerHeight);

        if(window.innerHeight < 760) {
            usePopupRef.current.style.width = `${(window.innerHeight-160)/1.3}px`;
        } else {
            usePopupRef.current.style.width = "500px";
        }

    }


    useEffect(()=>{

        axios.get("https://api.bio3s.com/api/main").then((res) => {
            setData(res.data.data);
        }).catch(() => {});
        
        // $(".section3").addClass("active");
        // fullpage
        $('#fullpage').fullpage({
            autoScrolling: true,
            scrollHorizontally: true,
            navigation:true,
            showActiveTooltip: true,
            // responsiveWidth: 1100,
        });

        $(window).bind('mousewheel', function(e){
            $(function () {
                if ($(".section2").hasClass("active") || $(".section3").hasClass("active") || $(".section4").hasClass("active")) {
                    // $(".header").addClass("active no-bg");
                    $(".header").addClass("active");
                }else{
                    // $(".header").removeClass("active no-bg");
                }
                if ($(".section1").hasClass("active")) {
                    setPlaying(true);
                }else{
                    setPlaying(false)
                }
                if ($(".section3").hasClass("active")) {
                    $("#fp-nav").addClass("top");
                }else{
                    $("#fp-nav").removeClass("top");
                }
            });
        });

        $("#fp-nav").click(function () {
            $(function () {
                if ($(".section2").hasClass("active") || $(".section3").hasClass("active") || $(".section4").hasClass("active")) {
                    // $(".header").addClass("active no-bg");
                    $(".header").addClass("active");
                }else{
                    // $(".header").removeClass("active no-bg");
                }
                if ($(".section1").hasClass("active")) {
                    setPlaying(true);
                }else{
                    setPlaying(false)
                }
                if ($(".section3").hasClass("active")) {
                    $("#fp-nav").addClass("top");
                }else{
                    $("#fp-nav").removeClass("top");
                }
            });
        });

        $(window).bind("touchend",function(e){
            if ($(".section2").hasClass("active") || $(".section3").hasClass("active") || $(".section4").hasClass("active")) {
                // $(".header").addClass("active no-bg");
                $(".header").addClass("active");
            }else{
                // $(".header").removeClass("active no-bg");
            }
            if ($(".section1").hasClass("active")) {
                setPlaying(true);
            }else{
                setPlaying(false)
            }
            if ($(".section3").hasClass("active")) {
                $("#fp-nav").addClass("top");
            }else{
                $("#fp-nav").removeClass("top");
            }
        });
        
        // section1
        let time = setTimeout(() => {
            if (document.querySelector('.slick-dots') != null) {
                let width = document.querySelector('.slick-dots').offsetWidth;
                let left = width + 16;
                setSlideControl(left);
            }
        }, 200);
        
        // document.getElementById('vid').play();

        // section5
        /* selectBox */
        const label = document.querySelector('.label');
        const options = document.querySelectorAll('.optionItem');
        // 클릭한 옵션의 텍스트를 라벨 안에 넣음
        const handleSelect = function(item) {
            label.innerHTML = item.textContent;
            label.parentNode.classList.remove('active');
        }
        // 옵션 클릭시 클릭한 옵션을 넘김
        options.forEach(function(option){
         option.addEventListener('click', function(){handleSelect(option)})
        })
        // 라벨을 클릭시 옵션 목록이 열림/닫힘
        label.addEventListener('click', function(){

            if(label.parentNode.classList.contains('active')) {
                label.parentNode.classList.remove('active');
            } else {
                label.parentNode.classList.add('active');
            }
        });

        $(function () {
            $('.label').click(function(){
                $(this).toggleClass("on");
                if ($(this).hasClass("on")) {
                    $('body').on('scroll touchmove mousewheel', function(event) {
                        event.stopPropagation();
                    })
                }else{
                    $('body').off('scroll touchmove mousewheel');
                }
            });
            $(".optionItem").click(function () {
                $(".label").toggleClass("on");
                if ($(".label").hasClass("on")) {
                    $('body').on('scroll touchmove mousewheel', function(event) {
                        event.stopPropagation();
                    })
                }else{
                    $('body').off('scroll touchmove mousewheel');
                }
            })
        });
        
        return ()=>{
            window.location.reload()
            clearTimeout(time);
        }
    },[]);

    useEffect(()=>{
        if (data) {
            if (data.popups.length === 0) {
                setPopCs("")
            }else{
                setPopCs("open")
            }
        }
    },[data]);

    // slider1
    function sliderPlay() {
        Main.slider.slickPlay();
    };
    function sliderPause() {
        Main.slider.slickPause();
    };
    // slider2
    function nextbtn() {
        Main.slider2.slickNext();
    }
    function prevbtn() {
        Main.slider2.slickPrev();
    }
    // slider3
    function nextbtn2() {
        Main.slider3.slickNext();
    }
    function prevbtn2() {
        Main.slider3.slickPrev();
    }

    const slSettings1 = {
        infinite : true, 
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        pauseOnHover : false,
        prevArrow : false,
        nextArrow : false,
        appendDots: dots => (
            <ul> {dots} </ul>
        ),
        customPaging: i => (
            <div>
                { '0' + (i + 1)}
                <div className='bar'><span></span></div>
            </div>
        )
    };
    const slSettings2 = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover : false,
        responsive: [ // 반응형 웹 구현 옵션
            {  
                breakpoint: 1048, //화면 사이즈 960px일 때
                settings: {
                    slidesToShow: 2,
                } 
            },
            {  
                breakpoint: 600, //화면 사이즈 960px일 때
                settings: {
                    slidesToShow: 1,
                } 
            }
        ]
    };
    const slSettings3 = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover : false,
        responsive: [ // 반응형 웹 구현 옵션
            {  
                breakpoint: 1200, //화면 사이즈 960px일 때
                settings: {
                    //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                    slidesToShow: 3,
                } 
            },
            {  
                breakpoint: 800, //화면 사이즈 960px일 때
                settings: {
                    //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                    slidesToShow: 2,
                } 
            },
            {  
                breakpoint: 640, //화면 사이즈 960px일 때
                settings: {
                    //위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
                    slidesToShow: 1,
                } 
            }
        ]
    };
    const vidSettings ={
        className: 'react-player fixed-bottom',
        width: '100%',
        height: '100%',
        controls: false,
        muted: true,
        loop: true
    };

    function inquiryEnroll() {
        axios.post("https://api.bio3s.com/api/register?name=" + input1 + " &company= " + input2 + " &phone= " + input3 + " &email= " + input4 + " &category= " + optionItem + " &content=" + content ).then((response) => {

        })
        .catch((error) => {

        });
    }

    useEffect(()=>{
        // function setCookie(name, value, day) {
        //     var date = new Date();
        //     date.setDate(date.getDate() + day);

        //     var mycookie = '';
        //     mycookie += name + "=" + value + ";";
        //     mycookie += "Expires=" + date.toUTCString();

        //     document.cookie = mycookie;
        // }
        // setCookie("ABCcorp", "Main", 3);
        
        // console.log(document.cookie);

        // function getCookie(name) {
        //     var cookies = document.cookie.split(";");
        //     console.log(cookies);

        //     for (let index = 0; index < cookies.length; index++) {
        //         if (cookies[index].indexOf(name) > -1) {
        //             // alert(cookies[index]);
        //         }
        //     }
        // }
        // getCookie('ABCcorp');


        var myPopup = document.querySelector(".popup"),
            checkbox = document.querySelector("#popup"),
            popupClose = document.querySelector(".close");

        // 쿠키 생성
        function setCookie(name, value, day) {
            var date = new Date(); // 현재 날짜 지정.
            date.setDate(date.getDate() + day);

            var mycookie = '';
            mycookie += name + "=" + value + ";";
            mycookie += "Expires=" + date.toUTCString();

            document.cookie = mycookie; // 쿠키 설정, 생성
        }

        // 쿠키 삭제
        function delCookie(name) {
            var date = new Date();
            date.setDate(date.getDate() - 1);

            // var setCookie = '';
            // setCookie += name + "=Main";
            // setCookie += "Expires=" + date.toUTCString();

            // document.cookie = setCookie; 
        };

        //쿠키 확인
        function checkCookie(name) {
            var cookies = document.cookie.split(";");

            var visited = false; // 방문 거짓

            for (var i in cookies) {
                if (cookies[i].indexOf(name) > -1) {
                    visited = true;

                }
            }



            if (visited) {
                // 재방문
                myPopup.style.display = "none";
            }else{
                // 신규방문
                myPopup.style.display = "block";
            }
        }
        checkCookie('Bio3.com');

        popupClose.addEventListener("click", function () {
            if (checkbox.checked) {
                //팝업을 다시 안보겠다. 팝업 닫고, 쿠키 생성.
                setCookie("Bio3.com", "Main", 1);
                myPopup.style.display = "none";
            }else{
                //팝업을 계속 본다. 팝업 닫고, 쿠키 제거.
                myPopup.style.display = "none";
                delCookie("Bio3.com");
            }
        })
    },[])

    // 태그 지우기
    function contentReplace(content) {
        var text = content;
        return text.replace(/(<([^>]+)>)/ig,"");
    }

    return (
        <div className='main' id="fullpage">
            <div className={'popupWrap ' + popCs}>
                <div className='popup' ref={usePopupRef}>
                    <div className='slide-wrap'>
                        <Swiper
                            pagination={true}
                            modules={[ Autoplay, Pagination]}
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={false}
                            autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            }}
                            >
                            {
                                data ?
                                data.popups.map((item, i)=>{
                                    return(
                                        <SwiperSlide key={i}>
                                            <div className='img-wrap'
                                            onClick={()=> window.open(item.link, '_blank')}>
                                                <img src={item.fileAddr} alt="" />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                                : null
                            }
                        </Swiper>
                    </div>
                    <div className='popup-btn-wrap'>
                        <label htmlFor="popup"><input type="checkbox" name="" id="popup" />오늘 하루 보지 않기</label>
                        <button className='close'>닫기</button>
                    </div>
                </div>
            </div>
            <div className="section section1">
                <Slider1 ref={slider => (Main.slider = slider)} {...slSettings1}>
                    {
                        data ? 
                            data.banners.map((item, i)=>{
                                return(
                                    <div key = {i}>
                                        <div className='slide-item'>
                                            <div className='slide-bg'>
                                                {
                                                    item.type == 0 
                                                    ?   <img src={item.fileAddr} alt="" />
                                                    :   <ReactPlayer {...vidSettings} playing= {playing} url= {item.fileAddr} playsinline/>
                                                }
                                            </div>
                                            {
                                                item.title != null && item.content != null
                                                ?   
                                                    <div className='txt-wrap'>
                                                        <div className='txt-top'>
                                                            <p>
                                                                {item.title}
                                                            </p>
                                                        </div>
                                                        <div className='txt-bt'>
                                                            <p>
                                                                {item.content}
                                                            </p>
                                                        </div>
                                                    </div>
                                                :   ""
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        :
                            ""
                    }
                    
                </Slider1>
                {/* 슬라이드 컨트롤러 */}
                <div 
                    className='slick-control'
                    style={{left : slideControl}}
                    >
                    <button className="button" onClick={() => {sliderPlay()}}>
                        <i className='xi-play'></i>
                    </button>
                    <button className="button" onClick={() => {sliderPause()}}>
                        <i className="xi-pause"></i>
                    </button>
                </div>
            </div>

            <div className="section section2">
                <div className='container'>
                    <div className='img-wrap'>
                        <img src={require("../images/circle1.jpeg")} alt="" />
                    </div>
                    <div className='txt-wrap'>
                        <div className='txt-top'>
                            <p>
                            조달청 우수제품 지정 <br />
                            노로엑스 손세정제
                            </p>
                        </div>
                        <div className='txt-ct'>
                            <p>
                            [ 우수조달물품 구매제도 혜택 ] <br />
                            1.          별도 계약절차를 거치지 않고 나라장터<br />
                                종합 쇼핑몰에서 바로 구매<br />
                            2.       공공기관 구매책임자에 대한 면책제도
                            </p>
                        </div>
                        <div className='txt-bt'>
                            <p>
                            *         공공기관은 중소기업 기술개발제품 법정 <br />
                            의무구매비율(10%  이상)  이 있습니다.
                            </p>
                        </div>
                        <Link to={"/Procurement/ProcurementDetail/" + 7 + "/" + 2 + "/" + 4} className='btn-st1'>더보기</Link>
                    </div>
                </div>
            </div>

            <div className="section section3">
                <div className='st3-wrap'>
                    <div className='txt-wrap'>
                        <p className='txt-top scd'>OUR PRODUCTS</p>
                        <p className='txt-bt'>조달청 우수제품 지정, 과학기술통신부로부터 지정된 우수연구개발 혁신제품과 신기술(NET), 신제품(NEP)<br /> 인증을 통해 개선된 개인위생 솔루션을 제공합니다.</p>
                    </div>
                    <div className='slider-wrap'>
                        <Slider2 ref={c => (Main.slider2 = c)} {...slSettings2}>
                            {
                                data ?
                                data.products.map((item, i)=>{
                                    return (
                                        <div className='slide-item' key={i}>
                                            <div className='img-wrap'>
                                                <img src={item.fileAddr} alt="" />
                                            </div>
                                            <div className='content-wrap'>
                                                <Link to={"/Product/ProductDetail/" + item.id}>{item.title}</Link>
                                                <div className='content'>
                                                    <div className='classify'>
                                                        <span><i className='xi-shop'></i></span>
                                                        <p>{item.tags}</p>
                                                    </div>
                                                    <i className='xi-long-arrow-right' 
                                                        onClick={() => {navigate("/Product/ProductDetail/" + item.id)}}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : ''
                            }
                        </Slider2>
                        <div className='btn-wrap'>
                            <button className="button" onClick={prevbtn}>
                                <i className='xi-angle-left'></i>
                            </button>
                            <button className="button" onClick={nextbtn}>
                                <i className='xi-angle-right'></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='section section4'>
                <div className='container'>
                    <h3 className='scd'>NEWS <span>&</span> NOTICE</h3>
                    <div className='slide-wrap'>
                        <Slider2 ref={c => (Main.slider3 = c)} {...slSettings3} >
                            {
                                data ?
                                data.boards.map((item, i)=>{
                                    return(
                                        <div className='slide-item' key={i}>
                                            <div className='flex-wrap'>
                                                <div className='txt-top'>
                                                    <p>{item.title}</p>
                                                </div>
                                                <div className='txt-ct'>
                                                    <p>{contentReplace(item.content)}</p>
                                                </div>
                                                <span>{item.created_at.substr(0, 10)}</span>
                                            </div>
                                            {
                                                item.type == 0 ?
                                                <Link to={"/PR/NewsDetail/" + item.id}><i className='xi-long-arrow-right'></i></Link>
                                                : <Link to={"/PR/NoticeDetail/" + item.id}><i className='xi-long-arrow-right'></i></Link>
                                            }
                                            
                                        </div>
                                    )
                                })
                                : ''
                            }
                        </Slider2>
                        <button className="sl-button left-btn" onClick={prevbtn2}>
                            <i className='xi-long-arrow-left'></i>
                        </button>
                        <button className="sl-button right-btn" onClick={nextbtn2}>
                            <i className='xi-long-arrow-right'></i>
                        </button>
                    </div>
                    <Link to={"/PR/News"} className="add-btn">더보기</Link>
                </div>
            </div>

            <div className='section section5'>
                <div className='container'>
                    <h3 className='scd'>INQUIRY</h3>
                    <div className='flex-box'>
                        <div className='left-box'>
                            <div className='top-wrap'>
                                <span>Q</span>
                                <p>문의내용을 입력해 주세요</p>
                            </div>
                            <div className='question-wrap'>
                                <textarea name="" id="txt" placeholder='문의내용을 입력해 주세요' onChange={()=>{
                                        setContent(document.getElementById('txt').value);
                                    }}></textarea>
                                <div className="selectBox2">
                                    <button className="label">문의 분류를 선택해 주세요</button>
                                    <ul className="optionList">
                                        <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("R&D(연구&개발)"))}}>R&D(연구&개발)</li>
                                        <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("노로-X 손세정제 거품비누형"))}}>노로-X 손세정제 거품비누형</li>
                                        <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("코록스 손소독제"))}}>코록스 손소독제</li>
                                        <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("빈가드가글 플러스"))}}>빈가드가글 플러스</li>
                                        <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("빈가드 시린이 치약"))}}>빈가드 시린이 치약</li>
                                        <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("빈가드 과일채소세척제"))}}>빈가드 과일채소세척제</li>
                                        <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("빈가드 V-CARE 스프레이"))}}>빈가드 V-CARE 스프레이</li>
                                        <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("기타"))}}>기타</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='right-box'>
                            <div className='top-wrap mb'>
                                <span>Q</span>
                                <p>상담을 위한 개인정보를 입력해 주세요</p>
                            </div>
                            <div className='question-wrap'>
                                <div className='ipt-wrap'>
                                    <span>문의자명</span>
                                    <input id='input1' type="text" placeholder='문의자명을 입력해 주세요.' onChange={()=>{
                                        setInput1(document.getElementById('input1').value);

                                    }} />
                                </div>
                                <div className='ipt-wrap'>
                                    <span>회사명</span>
                                    <input id='input2' type="text" placeholder='회사명을 입력해 주세요.' onChange={()=>{
                                        setInput2(document.getElementById('input2').value);

                                    }} />
                                </div>
                                <div className='ipt-wrap'>
                                    <span>연락처</span>
                                    <input id='input3' type="text" placeholder='문의자 연락처를 입력해 주세요.' onChange={()=>{
                                        setInput3(document.getElementById('input3').value);

                                    }} />
                                </div>
                                <div className='ipt-wrap'>
                                    <span>이메일</span>
                                    <input id='input4' type="text" placeholder='문의자 이메일을 입력해 주세요.' onChange={()=>{
                                        setInput4(document.getElementById('input4').value);

                                    }} />
                                </div>
                                <div className='ck-wrap'>
                                    <input type="checkbox" name="" id="agreement" className='ck-input' />
                                    <label htmlFor="agreement" className='ck-label' onClick={()=>{
                                        if (document.getElementById('agreement').checked == true) {
                                            setAgreement(false);

                                        }else{
                                            setAgreement(true);

                                        }
                                    }}>
                                        <span><i className='xi-check-min'></i></span>
                                        <p>개인정보 수집 및 이용 동의(필수)</p>
                                    </label>
                                    {/* <Link to={""}>전문보기<i className='xi-angle-right'></i></Link> */}
                                    <a href='/PersonalNformation' target='_blank' rel='noreferrer'>전문보기<i className='xi-angle-right'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn-st1" onClick={()=>{
                        if (!content || !optionItem || !input1 || !input2 || !input3 || !input4 || !agreement) {

                            setWarning(" on");
                        }else{

                            setWarning("");
                            setPopUp(" open");
                            inquiryEnroll();
                        }
                    }}>문의등록</button>
                    <p className={'warning' + warning}>내용을 모두 입력해주세요</p>
                    <div className={'pop-wrap' + popUp}>
                        <div className='pop-box'>
                            <i className='xi-check-circle'></i>
                            <span>등록완료</span>
                            <p>고객문의 등록이 완료되었습니다.<br/>
                                문의하신 사항해 대해 정확히 확인한 후 빠르게<br/>
                                답변 드리도록 하겠습니다. 감사합니다.</p>
                            <button onClick={()=>{window.location.reload()}} className='close-btn'>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer ftCss = { ftCss }></Footer>
        </div>
    );
}

export default Main;