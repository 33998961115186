import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ProcurementMap from "../sub-map/ProcurementMap"
import { useRef } from 'react';
// API
import axios from 'axios';

function Procurement() {
    let [data, setData] = useState();
    let [searchText, setSearchText] = useState("");
    let navigate = useNavigate();

    useEffect(()=>{
        axios.get("https://api.bio3s.com/api/productProdcurement?keyword=").then((res) => {
            setData(res.data.data.productProcurement);
        }).catch(() => {console.log("실패");});
    },[])

    function search() {
        console.log(searchText);
        axios.get("https://api.bio3s.com/api/productProdcurement?keyword=" + searchText )
            .then(res => {
                setData(res.data.data.productProcurement);
                // console.log(res.data.data.productProcurement);
            }).catch(() => {console.log("실패");});
    }

    useEffect(()=>{
        // console.log(data);
    },[data]);

    function paginationClick(url) {
        console.log(url);
        
        axios.get(url + "&keyword=" + searchText  ).then((res) => {
            setData(res.data.data.productProcurement);
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <div className='Procurement CleanTech'>
            <ProcurementMap></ProcurementMap>
            <div className='sub-con'>
                <div className='container'>
                    <div className='search-wrap'>
                        <input type="text" id='search' placeholder='검색어를 입력하세요' onKeyUp={()=>{if(window.event.keyCode==13){search()}}} onChange={()=>{
                            setSearchText(document.getElementById('search').value);
                            // console.log(searchText);
                        }} />
                        <button onClick={() => search()}><i className='xi-search'></i></button>
                    </div>
                    <ul className='product-list'>
                        {
                            data ?
                            data.data.length == 0 ?
                                <div className='noData-wrap'>
                                    <div className='noData-img'>
                                        <img src={require("../../images/noData.png")} alt="" />
                                    </div>
                                </div>
                            :   data.data.map((item, i) => {
                                console.log(item)
                                return(
                                    <li key={i}>
                                        <div className='img-wrap'>
                                            <img src={item.fileAddr} alt="" />
                                        </div>
                                        <div className='title-wrap'>
                                            <div className='classify'>
                                                <span><i className='xi-shop'></i></span>
                                                <p>{item.tags}</p>
                                            </div>
                                            <p className='title'>{item.title}</p>
                                            <button className='buy-btn' onClick={()=>{navigate("/Procurement/ProcurementDetail/" + item.id + "/" + item.ref_procurementList_id + "/" + 0)}}>상세보기</button>
                                        </div>
                                    </li>
                                )
                            })
                            : null
                        }
                    </ul>
                    <ul className='Pagination'>
                        {
                            data ?
                            data.links.map((item, i)=>{
                                if(item.label == '&laquo; Previous'){
                                    if (item.url != null) {
                                        return(
                                            <li key={i} className='prev' onClick={() => paginationClick(item.url)}>
                                                <i className='xi-angle-left-thin'></i>
                                            </li>
                                        )
                                    }else{
                                        return(
                                            <li key={i} className='prev ev-no' onClick={() => paginationClick(item.url)}>
                                                <i className='xi-angle-left-thin'></i>
                                            </li>
                                        )
                                    }
                                }else if(item.label == 'Next &raquo;'){
                                    if (item.url != null) {
                                        return(
                                            <li key={i} className='next' onClick={() => paginationClick(item.url)}>
                                                <i className='xi-angle-right-thin'></i>
                                            </li>
                                        )
                                    }else{
                                        return(
                                            <li key={i} className='next ev-no' onClick={() => paginationClick(item.url)}>
                                                <i className='xi-angle-right-thin'></i>
                                            </li>
                                        )
                                    }
                                }else{
                                    if(data.current_page == item.label){
                                        return(
                                            <li key={i} className="now" onClick={() => paginationClick(item.url)}>{item.label}</li>
                                        )
                                    }else{
                                        return(
                                            <li key={i} onClick={() => paginationClick(item.url)}>{item.label}</li>
                                        )
                                    }
                                }
                            })
                            : null
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}


export default Procurement;