import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AboutUsMap from '../sub-map/AboutUsMap';

function Cooperation() {
    let [ menuHeight1, setMenuHeight1] = useState('');
    let [ menuHeight2, setMenuHeight2] = useState('');
    let [ menuHeight3, setMenuHeight3] = useState('');

    return (
        <div className='AboutUs introduce cooperation'>
            <AboutUsMap tag2={"회사소개"} tag3={"회사 조직 및 협력기관"}></AboutUsMap>
            <div className='mb-sub-menu container'>
                <ul>
                    <li><Link to={"/AboutUs"}>BIO3S 목표</Link></li>
                    <li><Link to={"/AboutUs/outline"}>기업개요</Link></li>
                    <li><Link to={"/AboutUs/management"}>경영진 소개</Link></li>
                    <li className='now'><Link to={"/AboutUs/Cooperation"}>회사조직 및 협력기관</Link></li>
                </ul>
            </div>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container'>
                        <div className='img-wrap'>
                            <img className='pc' src={require("../../images/organization.png")} alt="" />
                            <img className='mb' src={require("../../images/mb-img/organization-m.png")} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cooperation;