import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ResearchMap from "../sub-map/ResearchMap"

function Exam() {
    return (
        <div className='research ovc exam'>
            <ResearchMap tag2={"OVC Technology"} tag3={"인체임상시험"}></ResearchMap>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container'>
                        <h3 className='h3-st1'>Human Clinical Trial by BIO3S</h3>
                        <div className='scroll-wrap'>
                            <div className='table-wrap'>
                                <img src={require("../../images/table.png")} alt="" />
                            </div>
                        </div>
                        <ul className='list-wrap'>
                            <li>Microbiology Spectrum, 2022,10:e01614-21</li>
                        </ul>
                        <div className='example-box'>
                            <div className='txt-wrap'>
                                <p className='txt-top'>MICROBIOLOGY SPECTRUM</p>
                                <p className='txt-bt'>Bean Extract-Based Gargle for Efficient Doagnosis of Active COVID-19 Infection Using Rapid Antigen Tests</p>
                            </div>
                            <button className='example-wrap' onClick={()=> window.open('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8849053/', '_blank')}>자세히 보기</button>
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <h3 className='h3-st1'>PCR 검증<span>COVID-19 Symptom onset within 6 days</span></h3>
                        <div className='img-wrap'>
                            <img className='pc' src={require("../../images/pcr.jpg")} alt="" />
                            <img className='mb' src={require("../../images/mb-img/pcr.png")} alt="" />
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Exam;