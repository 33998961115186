import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ResearchMap from "../sub-map/ResearchMap"

function Material() {
    return (
        <div className='research ovc material'>
            <ResearchMap tag2={"OVC Technology"} tag3={"핵심소재"}></ResearchMap>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container pc'>
                        <h3 className='h3-st1'>핵심소재</h3>
                        <div className='flex-wrap'>
                            <div className='fl-le'>
                                <img src={require("../../images/core.png")} alt="" />
                            </div>
                            <div className='fl-ri'>
                                <img src={require("../../images/core-txt.png")} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='container mb'>
                        <div className='img-wrap'>
                            <img src={require("../../images/mb-img/core-m.png")} alt="" />
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <h3 className='h3-st1'>MOA</h3>
                        <div className='img-wrap'>
                            <img className='pc' src={require("../../images/material.png")} alt="" />
                            <img className='mb' src={require("../../images/mb-img/moa.jpg")} alt="" />
                        </div>
                        <div className='example-box'>
                            <div className='txt-wrap'>
                                <p className='txt-top'>MICROBIOLOGY SPECTRUM</p>
                                <p className='txt-bt'>Bean Extract-Based Gargle for Efficient Doagnosis of Active COVID-19 Infection Using Rapid Antigen Tests</p>
                            </div>
                            <button className='example-wrap' onClick={()=> window.open('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8849053/', '_blank')}>자세히 보기</button>
                        </div>
                    </div>
                </div>
                <div className='section3'>
                    <div className='container'>
                        <h3 className='h3-st1'>비임상시험 주요결과</h3>
                        <ul className='flex-wrap'>
                            <li>
                                <h4><span>01</span>효능 및 안전성</h4>
                                <p>작두콩 추출물에 존재하는 Tcan(truncated canavalin)은 SARS-CoV-2와 결합하며 
                                    4-8개의 올리고머를 형성하여 상당히 강한 힘으로 SARS-CoV-2와 결합하며 
                                    70도까지도 안정된 구조를 보임을 확인함.</p>
                            </li>
                            <li>
                                <h4><span>02</span>소재 독성</h4>
                                <p>작두콩 유래 천연단백질은 작두콩을 식품용균주를 활용하여 추출한 원료로
                                    세포 및 호흡기 <span>독성(기도내 점적투여)이 없음을 안전성평가연구소에서 확인함.</span></p>
                            </li>
                            <li>
                                <h4><span>03</span>바이러스 탈리효과</h4>
                                <p>hACE2가 표면에 코팅된 ELISA를 이용하여 결합된 SARS-CoV-2의
                                    RBD의 탈리효과가 우수함을 확인함.</p>
                            </li>
                            <li>
                                <h4><span>04</span>바이러스 불활성화</h4>
                                <p>작두콩 추출물에 포함된 TCan이 인체코로나바이러스의 
                                    스파이크 단백질을 둘러싼 <span>바이러스를 불활성화 시키는 메카니즘</span>을
                                    전자현미경(한국기초과학지원연구원)을 통해 확인함.</p>
                            </li>
                            <li>
                                <h4><span>05</span>항바이러스 실험</h4>
                                <p>전북대학교 인수공통감염병연구소(BSL-3 시설)에서 실시한 항코로나바이러스
                                    물질시험에서 당사제공 작두콩추출물 또는 빈가드가글 플러스는 COVID-19
                                    (SARS-CoV-2) <span>바이러스의 세포 감염을 10nM이상의 농도에서 99.9% 저해하는
                                    효력 및 67.2배 바이러스 감소 효과</span> 가 있음을 확인하였음.</p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Material;