import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AboutUsMap from "../sub-map/AboutUsMap";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function Finance() {
  let [menuHeight1, setMenuHeight1] = useState("");
  let [menuHeight2, setMenuHeight2] = useState("");
  let [menuHeight3, setMenuHeight3] = useState("");

  return (
    <div className="AboutUs finance">
      <AboutUsMap tag2={"재무정보"}></AboutUsMap>
      <div className="sub-con">
        <div className="section1">
          <div className="container">
            <h3 className="h3-st1">BIO3S 손익추이</h3>
            <div className="flex-wrap">
              {/* <div className='graph-wrap'>
                                <div className='graph-box'>
                                    <Graph1></Graph1>
                                </div>
                            </div> */}
              <div className="table-wrap">
                <table>
                  <thead>
                    <tr>
                      <th scope="col">구분</th>
                      <th scope="col">2020</th>
                      <th scope="col">2021</th>
                      <th scope="col">2022</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>영업이익</td>
                      <td>-134</td>
                      <td>894</td>
                      <td>-505</td>
                    </tr>
                    <tr>
                      <td>영업이익율</td>
                      <td>-60%</td>
                      <td>34%</td>
                      <td>-37%</td>
                    </tr>
                    <tr className="bd-color">
                      <td className="color">매출액</td>
                      <td>222</td>
                      <td>2,625</td>
                      <td>1,355</td>
                    </tr>
                    <tr>
                      <td>자산</td>
                      <td>597</td>
                      <td>2,001</td>
                      <td>1,548</td>
                    </tr>
                    <tr>
                      <td>부채</td>
                      <td>11</td>
                      <td>547</td>
                      <td>634</td>
                    </tr>
                    <tr className="bd-color">
                      <td>자본</td>
                      <td>586</td>
                      <td>1,454</td>
                      <td>914</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <h3 className="h3-st1">기술 / 신용 / ESG 평가등급</h3>
            <div className="flex-wrap">
              {/* <div className='graph-wrap'>
                                <div className='graph-box'>
                                    <Graph2></Graph2>
                                </div>
                            </div> */}
              <div className="img-wrap">
                <img
                  src={require("../../images/confirmation01.png")}
                  alt=""
                  id="img1"
                />
                <img
                  src={require("../../images/confirmation.png")}
                  alt=""
                  id="img2"
                />
                <img src={require("../../images/confirmation03.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Graph1() {
  const data = [
    {
      name: "2019",
      매출액: 0.9,
    },
    {
      name: "2020",
      매출액: 222,
    },
    {
      name: "2021",
      매출액: 2625,
    },
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="매출액" fill="#09abbe" />
      </BarChart>
    </ResponsiveContainer>
  );
}

function Graph2() {
  const data = [
    {
      name: "2019",
      자본: 397,
    },
    {
      name: "2020",
      자본: 586,
    },
    {
      name: "2021",
      자본: 1454,
    },
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="자본" fill="#09abbe" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Finance;
