import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PRMap from "../sub-map/PRMap"
import 'swiper/css';

// API
import axios from 'axios';

function PR() {
    let [btn, setBtn] = useState("");
    let [data, setData] = useState();
    let [newData, setNewData] = useState();
    let [addNumber, setAddNumber] = useState(9);
    let [pop, setPop] = useState("")
    let [popImg, setPopImg] = useState();
    // toggleTab
    let [oldCategory, setOldCategory] = useState();
    const liRef = useRef(null);

    useEffect(()=>{
        axios.get("https://api.bio3s.com/api/gallery").then((res) => {
            setData(res.data.data.galleries);
        }).catch(() => {console.log("실패");});
    },[]);
    
    function changeData(e) {
        if(data != null){
            let copy = data;
            let copy2 = [];
            copy.map((item,i)=>{
                if (item.type == e) {
                    copy2.push(copy[i])
                }
            });
            setNewData(copy2);
            console.log(newData);
        }
    };

    function toggleTab(e) {
        if (e) {
            
            if(oldCategory){
                oldCategory.target.classList.remove("now");

            }else{

                if(liRef.current){
                    liRef.current.classList.remove("now");
                }

            }

            e.target.classList.add("now");
            setOldCategory(e);
        }
    }

    useEffect(()=>{
        changeData(0);
    },[data])
    
    useEffect(()=>{
        setAddNumber(9);
        setBtn("")
    },[newData])

    useEffect(()=>{
        if(newData != null){
            const count = Object.keys(newData).length;
            if ( count <= addNumber ) {
                setBtn(" dp-none")
            }
        }
    });

    return (
        <div className='PR Activity'>
            <PRMap tag2={"ESG 활동"}></PRMap>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container'>
                        <div className='img-wrap'>
                            <img src={require("../../images/vi-corevalues.png")} alt="" />
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <h3 className='h3-st2'>활동사진</h3>
                        <ul className='tab-wrap'>
                            <li ref={liRef} onClick={(e)=>{changeData(0); toggleTab(e)}} className='now'>Eco-Friendly</li>
                            <li onClick={(e)=>{changeData(1); toggleTab(e)}}>Good Neighbor</li>
                            <li onClick={(e)=>{changeData(2); toggleTab(e)}}>Clean Governance</li>
                        </ul>
                        <ul className='img-list'>
                            {
                                newData ?
                                newData.map((item, i)=>{
                                    if ( i < addNumber ) {
                                        return (
                                            <li key={i} onClick={()=>{
                                                setPop(" open");
                                                setPopImg(item.fileAddr);
                                            }}>
                                                <div className='img-wrap'>
                                                    <img src={item.fileAddr} alt="" />
                                                </div>
                                                <div className='txt-wrap'>
                                                    <p>[{item.register_date}]</p>
                                                    <p>{item.content}</p>
                                                </div>
                                            </li>
                                        )
                                    }
                                })
                                : null
                            }
                        </ul>
                        <button className={'btn-st1' + btn} onClick={()=>{setAddNumber(addNumber + 9)}}>더보기</button>
                    </div>
                </div>
                <div className={'pop-up' + pop}>
                    <div className='img-wrap' onClick={()=>{setPop("")}}>
                        <img src={popImg} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PR;