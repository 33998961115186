import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ProducthMap from "../sub-map/ProductMap"
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import Parser from 'html-react-parser'
// API
import axios from 'axios';

function ProductDetailEn() {
    let navigate = useNavigate();
    let { id } = useParams();
    const swiperRef1 = useRef();
    const swiperRef2 = useRef();
    let [data, setData] = useState();
    let [copy, setCopy] = useState();
    const faqList = useRef([]);
    const [oldFaqList, setOldFaqList] = useState();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{
        axios.get('https://api.bio3s.com/api/en/product/detail?id=' + id)
            .then(res => {
                setData(res.data.data);
            }).catch(() => {console.log("실패");});
    },[]);

    function toggleClass(i) {
        
        if (oldFaqList) {
            oldFaqList.classList.remove("open");
        } 

        if (!isOpen) {

            faqList.current[i].classList.add("open");
            setIsOpen(true);

        } else {

            if(oldFaqList == faqList.current[i]){
                faqList.current[i].classList.remove("open");
                setIsOpen(false);
            }else{
                faqList.current[i].classList.add("open");
                setIsOpen(true);
            }

        }
        setOldFaqList(faqList.current[i]);

    }

    return (
        <div className='Product ProductDetail'>
            <ProducthMap tag2={"Clean Tech"}></ProducthMap>
            <div className='sub-con'>
                <div className='section1'>
                    <img src={require("../../images/product_bg.png")} alt="" className='st1-bg' />
                    <div className='container'>
                        <div className='img-wrap'>
                            <div className='img-wrap2'>
                                <img src={data ? data.product[0].fileAddr : null} alt="" />
                            </div>
                        </div>
                        <div className='con-box'>
                            <span className='classify1'>{data ? data.product[0].category : null}</span>
                            <div className='txt-wrap'>
                                <p className='txt-top'>{data ? data.product[0].title : null}</p>
                                <div className='txt-ct'>
                                    <span><i className='xi-shop'></i></span>
                                    <p>{data ? data.product[0].tags : null}</p>
                                </div>
                                <p className='txt-bt'>{data ? data.product[0].short_content : null}</p>
                            </div>
                            <button 
                            onClick={()=> window.open(data ? data.product[0].sel_link : null, '_blank')}
                            className="buy-btn">구매하기 <i className='xi-long-arrow-right'></i></button>
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <div className='swiper-wrap'>
                            <Swiper
                            onSwiper={(swiper) => {
                                swiperRef1.current = swiper;
                            }}
                            spaceBetween={20}
                            slidesPerView={'auto'}
                            loop={false}
                            breakpoints={{
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40
                                },
                            }}
                            >
                                {
                                    data ?
                                    data.socials.map((item, i)=>{
                                        return(
                                            <SwiperSlide key={i}>
                                                <div className='img-wrap'>
                                                    <img src={item.fileAddr} alt="" />
                                                </div>
                                                <div className='txt-wrap'>
                                                    <p>{item.title}</p>
                                                    <button onClick={()=> window.open(item.link, '_blank')}>바로가기</button>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                    : null
                                }
                            </Swiper>
                            <button className='slidePrev slideBtn' onClick={() => swiperRef1.current.slidePrev()}><i className='xi-long-arrow-left'></i></button>
                            <button className='slideNext slideBtn' onClick={() => swiperRef1.current.slideNext()}><i className='xi-long-arrow-right'></i></button>
                        </div>
                    </div>
                </div>
                <div className='section3'>
                    <div className='container'>
                        <div className='content-wrap'>
                            {
                                data ? 
                                data.product[0].content ?
                                Parser(data.product[0].content) 
                                : null
                                : null
                            }
                        </div>
                    </div>
                </div>
                <div className='section4'>
                    <div className='container'>
                        <h3 className='h3-st1'>인증 및 연구개발성과</h3>
                        <div className='swiper-wrap'>
                            <Swiper
                            onSwiper={(swiper) => {
                                swiperRef2.current = swiper;
                            }}
                            spaceBetween={20}
                            slidesPerView={'auto'}
                            loop={false}
                            breakpoints={{
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 32
                                },
                            }}
                            >   
                                {
                                    data ?
                                    data.certificates.map((item, i)=>{
                                        return(
                                            <SwiperSlide key={i}>
                                                <div className='img-wrap'>
                                                    <img src={item.fileAddr} alt="" />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                    : null
                                }
                            </Swiper>
                            <button className='slidePrev slideBtn' onClick={() => swiperRef2.current.slidePrev()}><i className='xi-long-arrow-left'></i></button>
                            <button className='slideNext slideBtn' onClick={() => swiperRef2.current.slideNext()}><i className='xi-long-arrow-right'></i></button>
                        </div>
                    </div>
                </div>
                <div className='section5'>
                    <div className='container'>
                        <h3 className='h3-st1'>FAQ</h3>
                        <ul className='faq-wrap'>
                            {
                                data ?
                                data.faqs.map((item, i)=>{

                                    return(
                                        <li key={i}>
                                            <div className='question-wrap'>
                                                <span className='q-wrap'>Q</span>
                                                <p>{item.title}</p>
                                                <button onClick={()=>{toggleClass(i)}} className='plus-wrap'><i className='xi-plus'></i></button>
                                            </div>
                                            <div ref={(e)=>faqList.current[i]=e} className='answer-wrap'>
                                                <div className='answer-box'>
                                                    <span className='a-wrap'>A</span>
                                                    <p>{item.content}</p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                                : null
                            }
                        </ul>
                        <button onClick={()=>navigate("/en/Product")} className='inventory-btn'>목록으로</button>
                    </div>
                </div>
                <Link to={"/en/Contact"} className='inqu-btn'></Link>
            </div>
        </div>
    )
}

export default ProductDetailEn;