import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AboutUsMap from '../sub-map/AboutUsMap';
import $ from 'jquery';
// API
import axios from 'axios';

function HistoryEn() {
    let [data, setData] = useState();
    let [ctYear, setCtYear] = useState([]);
    let [year, setYear] = useState([]);

    useEffect(()=>{
        axios.get("https://api.bio3s.com/api/en/histories").then((res) => {
            console.log(res.data.data);
            setData(res.data.data);
        }).catch(() => {console.log("실패");});
    },[]);
    
    useEffect(()=>{
        setCtYear(document.querySelectorAll(".ct-year"))
        setYear(document.querySelectorAll(".year"));
    },[data])

    function yearScroll(i) {
        $('html, body').animate({
            scrollTop: $("#" + i).offset().top - window.innerHeight / 2 + $("#" + i).innerHeight() / 2
        }, 500);
        console.log();
    }

    window.addEventListener("scroll", (event) => {
        let windowY = document.documentElement.scrollTop;
        
        if (year) {
            for (let index = 0; index < year.length; index++) {
                let y = window.pageYOffset + year[index].getBoundingClientRect().top - (window.innerHeight / 2) + (year[index].offsetHeight / 2);
                // console.log('y =' + windowY + " - " + y)
                if (windowY + year[index].offsetHeight / 2 > y && y > windowY - year[index].offsetHeight / 2) {
                    ctYear[index].classList.add("active");
                    // console.log(ctYear[index]);
                }else{
                    ctYear[index].classList.remove("active");
                }
            }
        }
        
    });

    return (
        <div className='AboutUs history'>
            <AboutUsMap tag2={"Company History"}></AboutUsMap>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container'>
                        <h3 className='h3-st2'>Company History</h3>
                        <div className='history-wrap'>
                            <div className='control-wrap'>
                                <div className='control-box active'>
                                    <div className='control-top'>
                                        <span>History</span>
                                    </div>
                                    <div className='control-bt'>
                                        {
                                            data ?
                                            data.category.map((item, i)=>{
                                                return(
                                                    <div className='control-time' key={i}>
                                                        <span>{item.title}</span>
                                                        <ul className='control-year'>
                                                            {
                                                                data.year.map((item2, i)=>{
                                                                    if (item.id == item2.ref_id) {
                                                                        return(
                                                                            <li key={i} className="ct-year" onClick={()=>{
                                                                                yearScroll(i);
                                                                            }}>{item2.year}</li>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            })
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                data ?
                                data.category.map((item, i)=>{
                                    return(
                                        <div className='time-wrap' key={i}>
                                            <span className='time'>{item.title}</span>
                                            {
                                                data.year.map((item2, i)=>{
                                                    if (item.id == item2.ref_id) {
                                                        return(
                                                            <div className='year-wrap' key={i} id={i}>
                                                                <span className='year'>{item2.year}</span>
                                                                <ul className='month-wrap'>
                                                                    {
                                                                        data.history.map((item3, i)=>{
                                                                            if (item2.year == item3.year) {
                                                                                return(
                                                                                    <li key={i}>
                                                                                        <span>
                                                                                            {
                                                                                                item3.month.toString().length == 1 ?
                                                                                                "0" + item3.month + "."
                                                                                                : item3.month + "."
                                                                                            }
                                                                                        </span>
                                                                                        <p>{item3.content}</p>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    )
                                })
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HistoryEn;