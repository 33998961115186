import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContactMap from "../sub-map/ContactMap"

// API
import axios from 'axios';

function ContactEn() {
    // INQUIRY
    let [content, setContent] = useState();
    let [optionItem, setOptionItem] = useState();
    let [input1, setInput1] = useState();
    let [input2, setInput2] = useState();
    let [input3, setInput3] = useState();
    let [input4, setInput4] = useState();
    let [agreement, setAgreement] = useState();
    let [popUp, setPopUp] = useState("");
    let [warning, setWarning] = useState("");

    // useEffect(()=>{
    //     setOptionItem(optionItem.replace(/&/g,"%26"))
    //     // optionItem = optionItem.replace(/&/g,"%26");
    // },[optionItem])
    


    function inquiryEnroll() {
        axios.post("https://api.bio3s.com/api/register?name=" + input1 + " &company= " + input2 + " &phone= " + input3 + " &email= " + input4 + " &category= " + optionItem + " &content=" + content ).then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.log(error.response);
        });
    }

    useEffect(()=>{
        /* selectBox */
        const label = document.querySelector('.label');
        const options = document.querySelectorAll('.optionItem');
        // 클릭한 옵션의 텍스트를 라벨 안에 넣음
        const handleSelect = function(item) {
        label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
        }
        // 옵션 클릭시 클릭한 옵션을 넘김
        options.forEach(function(option){
        option.addEventListener('click', function(){handleSelect(option)})
        })
        // 라벨을 클릭시 옵션 목록이 열림/닫힘
        label.addEventListener('click', function(){
        if(label.parentNode.classList.contains('active')) {
            label.parentNode.classList.remove('active');
        } else {
            label.parentNode.classList.add('active');
        }
        });
    },[])

    return (
        <div className='Contact customer-enquiry'>
            <ContactMap tag2={"Q&A"}></ContactMap>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container'>
                        <h3 className='h3-st2'>Q&A</h3>
                        <div className='way-wrap'>
                            <div>
                                <span className='icon'><i className='xi-call'></i></span>
                                <span className='txt'>Contact</span>
                                <p>1899-5397</p>
                            </div>
                            <div>
                                <span className='icon'><i className='xi-mail'></i></span>
                                <span className='txt'>E-mail</span>
                                <p>contact@bio3s.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <div className='top-wrap'>
                            <span className='q-box'>Q</span>
                            <p>Please enter your inquiry</p>
                        </div>
                        <div className="selectBox2">
                            <button className="label">Please select an inquiry classification <i className='xi-caret-down'></i></button>
                            <ul className="optionList">
                                <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("R&D(연구&개발)"))}}>R&D(연구&개발)</li>
                                <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("노로-X 손세정제 거품비누형"))}}>노로-X 손세정제 거품비누형</li>
                                <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("코록스 손소독제"))}}>코록스 손소독제</li>
                                <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("빈가드가글 플러스"))}}>빈가드가글 플러스</li>
                                <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("빈가드 시린이 치약"))}}>빈가드 시린이 치약</li>
                                <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("빈가드 과일채소세척제"))}}>빈가드 과일채소세척제</li>
                                <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("빈가드 V-CARE 스프레이"))}}>빈가드 V-CARE 스프레이</li>
                                <li className="optionItem" onClick={()=>{setOptionItem(encodeURIComponent("기타"))}}>기타</li>
                            </ul>
                        </div>
                        <textarea name="" id="txt" placeholder='Please enter your inquiry' onChange={()=>{
                            setContent(document.getElementById('txt').value);
                            console.log(content);
                        }}></textarea>
                        <div className='top-wrap'>
                            <span className='q-box'>Q</span>
                            <p>Please enter your personal information for consultation</p>
                        </div>
                        <div className='question-wrap'>
                            <div className='ipt-wrap'>
                                <span>Name</span>
                                <input id='input1' type="text" placeholder='Please select a classification of the inquiry.' onChange={()=>{
                                    setInput1(document.getElementById('input1').value);
                                    console.log(input1);
                                }} />
                            </div>
                            <div className='ipt-wrap'>
                                <span>Company</span>
                                <input id='input2' type="text" placeholder='Please enter a company name.' onChange={()=>{
                                    setInput2(document.getElementById('input2').value);
                                    console.log(input2);
                                }} />
                            </div>
                            <div className='ipt-wrap'>
                                <span>Contact</span>
                                <input id='input3' type="text" placeholder='Please enter contact information for the person in charge.' onChange={()=>{
                                    setInput3(document.getElementById('input3').value);
                                    console.log(input3);
                                }} />
                            </div>
                            <div className='ipt-wrap'>
                                <span>E-mail</span>
                                <input id='input4' type="text" placeholder='Please enter an email from the person in charge.' onChange={()=>{
                                    setInput4(document.getElementById('input4').value);
                                    console.log(input4);
                                }} />
                            </div>
                            <div className='ck-wrap'>
                                <input type="checkbox" name="" id="agreement" className='ck-input' />
                                <label htmlFor="agreement" className='ck-label' onClick={()=>{
                                    if (document.getElementById('agreement').checked == true) {
                                        setAgreement(false);
                                        console.log(agreement);
                                    }else{
                                        setAgreement(true);
                                        console.log(agreement);
                                    }
                                }}>
                                    <span><i className='xi-check-min'></i></span>
                                    <p>Consent to collect and use personal information (required)</p>
                                </label>
                                <a href='/en/PersonalNformation' target='_blank' rel='noreferrer'>View the full text</a>
                            </div>
                        </div>
                        <button className="btn-st1" onClick={()=>{
                            if (!content || !optionItem || !input1 || !input2 || !input3 || !input4 || !agreement) {
                                console.log("내용없음");
                                setWarning(" on");
                            }else{
                                console.log("내용있음");
                                setWarning("");
                                setPopUp(" open");
                                inquiryEnroll();
                            }
                        }}>Registration</button>
                        <p className={'warning' + warning}>Please enter all the contents.</p>
                        <div className={'pop-wrap' + popUp}>
                            <div className='pop-box'>
                                <i className='xi-check-circle'></i>
                                <span>Registration completed</span>
                                <p>Customer inquiry registration has been completed.
                                    We will check your inquiry accurately and reply quickly. Thank you.</p>
                                <button onClick={()=>{window.location.reload()}} className='close-btn'>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactEn;