import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ResearchMap({tag2 , tag3}) {
    let [ menuHeight1, setMenuHeight1] = useState('');
    let [ menuHeight2, setMenuHeight2] = useState('');
    let [ menuHeight3, setMenuHeight3] = useState('');

    return (
        <div className='sub-top'>
            <div className='sub-banner'>
                <div className='banner-img'></div>
                <h2>R&D</h2>
                <p>Striving To be Best biomedical Company leading the fight against viruses</p>
            </div>
            <div className='sub-map'>
                <div className='container'>
                    <Link to={'/en'} className='home-btn'><i className='xi-home'></i></Link>
                    <div className={'sub-menu ' + menuHeight1}>
                        <div className='show' onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>
                            <p>R&D</p>
                            <i className='xi-angle-down'></i>
                        </div>
                        <ul className='sub-menu-list'>
                            <li><Link to={"/en/AboutUs"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>About Us</Link></li>
                            <li><Link to={"/en/Research"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>R&D</Link></li>
                            <li><Link to={"/en/Product"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>PRODUCT</Link></li>
                            <li><Link to={"/en/Procurement"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>Public Procurement</Link></li>
                            <li><Link to={"/en/PR"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>MEDIA</Link></li>
                            <li><Link to={"/en/Contact"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>CONTACT US</Link></li>
                        </ul>
                    </div>
                    <div className={'sub-menu ' + menuHeight2}>
                        <div className='show' onClick={()=>{ menuHeight2 ? setMenuHeight2('') : setMenuHeight2('open') }}>
                            <p>{tag2}</p>
                            <i className='xi-angle-down'></i>
                        </div>
                        <ul className='sub-menu-list'>
                            <li><Link to={"/en/Research"} onClick={()=>{ menuHeight2 ? setMenuHeight2('') : setMenuHeight2('open') }}>OVC Technology</Link></li>
                            <li><Link to={"/en/Research/Patent"} onClick={()=>{ menuHeight2 ? setMenuHeight2('') : setMenuHeight2('open') }}>Patents & Certificates</Link></li>
                        </ul>
                    </div>


                    <div className={'sub-menu3 ' + 'sub-menu ' + menuHeight3}>
                        <div className='show' onClick={()=>{ menuHeight3 ? setMenuHeight3('') : setMenuHeight3('open') }}>
                            <p>{tag3}</p>
                            <i className='xi-angle-down'></i>
                        </div>
                        <ul className='sub-menu-list'>
                            <li><Link to={"/en/Research"} onClick={()=>{ menuHeight3 ? setMenuHeight3('') : setMenuHeight3('open') }}>About OVC Technology</Link></li>
                            <li><Link to={"/en/Research/material"} onClick={()=>{ menuHeight3 ? setMenuHeight3('') : setMenuHeight3('open') }}>Bean Extract as a Key Material</Link></li>
                            <li><Link to={"/en/Research/Exam"} onClick={()=>{ menuHeight3 ? setMenuHeight3('') : setMenuHeight3('open') }}>Human Clinical Trials</Link></li>
                            <li><Link to={"/en/Research/Expandability"} onClick={()=>{ menuHeight3 ? setMenuHeight3('') : setMenuHeight3('open') }}>Coronavirus and Beyond</Link></li>
                        </ul>
                    </div>
                    <div className={'sub-menu4 ' + 'sub-menu ' + menuHeight3}>
                        <div className='show' onClick={()=>{ menuHeight3 ? setMenuHeight3('') : setMenuHeight3('open') }}>
                            <p>{tag3}</p>
                            <i className='xi-angle-down'></i>
                        </div>
                        <ul className='sub-menu-list'>
                            <li><Link to={"/en/Research/Patent"} onClick={()=>{ menuHeight3 ? setMenuHeight3('') : setMenuHeight3('open') }}>List of Patents</Link></li>
                            <li><Link to={"/en/Research/Virus"} onClick={()=>{ menuHeight3 ? setMenuHeight3('') : setMenuHeight3('open') }}>Patents related to Virus Research</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResearchMap;