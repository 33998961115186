import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
// import  logo  from '';

function Header() {
    let navigate = useNavigate();
    let [ hdActive, setHdActive ] = useState('');
    let [ scrollPosition, setScrollPosition ] = useState(0);
    let [ siteMap, setSiteMap ] = useState('');
    let [ toggle, setToggle ] = useState({tg1: '', tg2: '', tg3: '', tg4: '', tg5: '', tg6: ''});
    let tgHandler = (event) =>{
        let copy = { ...toggle };
        let activetab = event.currentTarget.id;
        for(let key in copy) {
            if (key === activetab) {
                if (copy[key] == '') {
                    copy[key] = ' open'
                }else{
                    copy[key] = ''
                }
            }else{
                copy[key] = ''
            }
        };
        setToggle(copy);
    }


    let updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }
    useEffect(()=>{
        window.addEventListener('scroll', updateScroll);
        if (scrollPosition == 0) {
            setHdActive("");
        }else{
            setHdActive(' active')
        };
        if (siteMap == ' sitemap') {
            document.querySelector('body').style.overflow = 'hidden';
            document.querySelector('body').style.height= '100%';
        }else{
            document.querySelector('body').style.overflow = 'unset';
            document.querySelector('body').style.height= 'unset';
        };
    });

    return (
        <div className={"header" + hdActive + siteMap + " active"}>
            <h1 className='logo' onClick={() => {navigate("")}}></h1>
            <div className='header-ri'>
                <div className='nav-wrap'>
                    <div className='mb-logo-wrap' onClick={() => {navigate("")}}>
                        <img src={require("../images/LOGO.png")} alt="" />
                    </div>
                    <ul className='hd-nav'>
                        <li>
                            <Link to={'/en/AboutUs'} >About Us</Link>
                            <div className='mb-handle' id='tg1' onClick={tgHandler}>
                                <p>About Us</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg1}>
                                <li onClick={() => {navigate("/en/AboutUs"); setSiteMap('')}}>A Company Profile</li>
                                <li className='sm-li' onClick={() => {navigate("/en/AboutUs/outline"); setSiteMap('')}}>A Company’s Business Model</li>
                                <li className='sm-li' onClick={() => {navigate("/en/AboutUs/management"); setSiteMap('')}}>CEO Greeting</li>
                                <li className='sm-li last' onClick={() => {navigate("/en/AboutUs/Cooperation"); setSiteMap('')}}>Organizational Chart</li>
                                <li onClick={() => {navigate("/en/AboutUs/history"); setSiteMap('')}}>Company History</li>
                                <li onClick={() => {navigate("/en/AboutUs/finance"); setSiteMap('')}}>Financial Information</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/en/Research'} >R&D</Link>
                            <div className='mb-handle' id='tg2' onClick={tgHandler}>
                                <p>R&D</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg2}>
                                <li onClick={() => {navigate("/en/Research"); setSiteMap('')}}>OVC Technology</li>
                                <li className='sm-li' onClick={() => {navigate("/en/Research/material"); setSiteMap('')}}>Bean Extract as a Key Material</li>
                                <li className='sm-li' onClick={() => {navigate("/en/Research/Exam"); setSiteMap('')}}>Human Clinical Trials</li>
                                <li className='sm-li last' onClick={() => {navigate("/en/Research/Expandability"); setSiteMap('')}}>Coronavirus and Beyond</li>
                                <li onClick={() => {navigate("/en/Research/Patent"); setSiteMap('')}}>Patents & Certificates</li>
                                <li onClick={() => {navigate("/en/Research/Virus"); setSiteMap('')}}>Patents related to Virus Research</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/en/Product'} >Product</Link>
                            <div className='mb-handle' id='tg3' onClick={tgHandler}>
                                <p>Product</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg3}>
                                <li onClick={() => {navigate("/en/Product"); setSiteMap('')}}>Clean Tech</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/en/procurement'} >Public Procurement</Link>
                            <div className='mb-handle' id='tg6' onClick={tgHandler}>
                                <p>Public Procurement</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg6}>
                                <li onClick={() => {navigate("/en/procurement"); setSiteMap('')}}>Public Procurement</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/en/PR'} >Media</Link>
                            <div className='mb-handle' id='tg4' onClick={tgHandler}>
                                <p>Media</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg4}>
                                <li onClick={() => {navigate("/en/PR"); setSiteMap('')}}>ESG Activities</li>
                                <li onClick={() => {navigate("/en/PR/News"); setSiteMap('')}}>News</li>
                                <li className='sm-li' onClick={() => {navigate("/en/PR/News"); setSiteMap('')}}>Company News</li>
                                <li className='sm-li last' onClick={() => {navigate("/en/PR/Notice"); setSiteMap('')}}>Notice</li>
                                <li onClick={() => {navigate("/en/PR/SNS"); setSiteMap('')}}>SNS</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/en/Contact'} >Contact Us</Link>
                            <div className='mb-handle' id='tg5' onClick={tgHandler}>
                                <p>Contact Us</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg5}>
                                <li onClick={() => {navigate("/en/Contact"); setSiteMap('')}}>Q&A</li>
                                <li onClick={() => {navigate("/en/Contact/Directions"); setSiteMap('')}}>Directions</li>
                            </ul>
                        </li>
                    </ul>
                    <button className='close-btn' onClick={() => { setSiteMap("") }}>
                        <i className='xi-close'></i>
                    </button>
                </div>
                
                <span className='hd-btn' onClick={() => { navigate("/") }}><i className='xi-globus'></i></span>
                <span className='hd-btn' onClick={() => { setSiteMap(" sitemap") }}><i className='xi-bars'></i></span>
            </div>
        </div>
    )
}

export default Header;