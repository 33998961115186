import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ResearchMap from "../sub-map/ResearchMap"

function MaterialEn() {
    return (
        <div className='research ovc material'>
            <ResearchMap tag2={"OVC Technology"} tag3={"Bean Extract as a Key Material"}></ResearchMap>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container pc'>
                        <h3 className='h3-st1'>Bean Extract As a Key Biomaterial</h3>
                        <div className='flex-wrap'>
                            <div className='fl-le'>
                                <img src={require("../../images/core.png")} alt="" />
                            </div>
                            <div className='fl-ri'>
                                <img src={require("../../enImages/core-txt.png")} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='container mb'>
                        <div className='img-wrap'>
                            <img src={require("../../enImages/mb-img/core-m.png")} alt="" />
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <h3 className='h3-st1'>MOA</h3>
                        <div className='img-wrap'>
                            <img className='pc' src={require("../../images/material.png")} alt="" />
                            <img className='mb' src={require("../../images/mb-img/moa.jpg")} alt="" />
                        </div>
                        <div className='example-box'>
                            <div className='txt-wrap'>
                                <p className='txt-top'>MICROBIOLOGY SPECTRUM</p>
                                <p className='txt-bt'>Bean Extract-Based Gargle for Efficient Doagnosis of Active COVID-19 Infection Using Rapid Antigen Tests</p>
                            </div>
                            <button className='example-wrap' onClick={()=> window.open('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8849053/', '_blank')}>Read more</button>
                        </div>
                    </div>
                </div>
                <div className='section3'>
                    <div className='container'>
                        <h3 className='h3-st1'>Key Achievements of Non-Clinical Tests</h3>
                        <ul className='flex-wrap'>
                            <li>
                                <h4><span>01</span>Effects/Stability</h4>
                                <p>Truncated Canavalin (TCan) in Jackbean forms oligomeric structure 
                                    and binds to SARS-CoV-2 with a fairly strong force, 
                                    showing a stable structure up to 70℃.</p>
                            </li>
                            <li>
                                <h4><span>02</span>Toxicity</h4>
                                <p>No Cell or Respiratory Toxicity Found in the Bean Proteins</p>
                            </li>
                            <li>
                                <h4><span>03</span>Virus Detachment</h4>
                                <p>The Jackbean Proteins Have Strong Effects on the Detachment 
                                    of Receptor Binding Domains of SARS-CoV-2 Attached to hACE2.</p>
                            </li>
                            <li>
                                <h4><span>04</span>Virus Inactivation</h4>
                                <p>Truncated Canavalin (TCan) in Jackbean Binds to the Spike Proteins 
                                    of SARS-CoV-2 and Inhibits the Entry of SARS-CoV-2 into Host Cells.</p>
                            </li>
                            <li>
                                <h4><span>05</span>Antivirus Tests</h4>
                                <p>The Jackbean Proteins Inhibited SARS-CoV-2 Replication 67.2 Times 
                                    in Animal Models Compared to Negative Controls.</p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MaterialEn;