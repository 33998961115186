import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ResearchMap from "../sub-map/ResearchMap"

function ExpandabilityEn() {
    return (
        <div className='research ovc Expandability'>
            <ResearchMap tag2={"OVC Technology"} tag3={"Coronavirus and Beyond"}></ResearchMap>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container'>
                        <h3 className='h3-st1'>Scalable Diagnostic Field<span>Cancer-Related Markers Currently Found in Saliva</span></h3>
                        <ul className='flex-wrap'>
                            <li>
                                <h4><span>01</span>HIV</h4>
                                <p className='txt-ct'>HIV Antibodies, Identifying HIV Antibodies</p>
                                <p>OraQuick In-Home HIV Test OraQuick ADVANCE Rapid HIV-l/2 Antibody Test,
                                    OraSure HIVl Western Blot</p>
                            </li>
                            <li>
                                <h4><span>02</span>HCV</h4>
                                <p className='txt-ct'>HCV antibody</p>
                                <p>OraQuick HCV Rapid Antibody Test</p>
                            </li>
                            <li>
                                <h4><span>03</span>Substance Abuse</h4>
                                <p className='txt-ct'>NIDAS 혈액패널 + barbiturates, (meth)amphetamines,
                                    benzodiazepines, methadone, cocaine, opiates,
                                    marijuana, phencyclidine</p>
                                <p>Intercept, SALIVASCREEN 5 Professional</p>
                            </li>
                            <li>
                                <h4><span>04</span>Blood Alcohol</h4>
                                <p className='txt-ct'>Ethanol</p>
                                <p>Q.E.D. Saliva Alcohol Test</p>
                            </li>
                            <li>
                                <h4><span>05</span>Hormone</h4>
                                <p className='txt-ct'>Estradiol, progesterone, testosterone, DHEA, cortisol</p>
                                <p>ZRT Saliva Test</p>
                            </li>
                            <li>
                                <h4><span>06</span>Insurance/Addiction</h4>
                                <p className='txt-ct'>Cocaine metabolite, cotinine, cannabinoids, opiates, 
                                    phencyclidine </p>
                                <p>MICRO-PLATE EIA & AUTO-LYTE</p>
                            </li>
                        </ul>
                        <div className='bt-le'>
                            <p>Development of Diagnostic Technology Using Genetic Information in Blood and Saliva</p>
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <h3 className='h3-st1'>Scalable Diagnostic Field<span>cancer-related markers currently found in saliva</span></h3>
                        <ul className='list-wrap'>
                            <li>
                                <div className='img-wrap'><img src={require("../../images/ex-icon-01.png")} alt="" /></div>
                                <div className='txt-wrap'>
                                    <h4>Head and Neck Cancer</h4>
                                    <p>Interleukin-1 (IL-1), IL-1β, IL-6, IL-8 (단백질과 mRNA), tumor necrosis factor (TNF), transferrin, CD44, CD59,<br/>
                                        zinc finger protein 510 (ZFN510) peptide, endothelin-1 (ET-1), HNP-1, cytokeratin-19 조각 (CYFRA-21-1) ORAOV1 관련 DNA 조각,<br/>
                                        miRNA-200a, mtDNA (Cox I, II), lncRNA (HOTAIR, MALAT-1)</p>
                                </div>
                            </li>
                            <li>
                                <div className='img-wrap'><img src={require("../../images/ex-icon-02.png")} alt="" /></div>
                                <div className='txt-wrap'>
                                    <h4>Pancreatic Cancer</h4>
                                    <p>KRAS, MBD3L2, ACRV1, DPM1 mRNAs</p>
                                </div>
                            </li>
                            <li>
                                <div className='img-wrap'><img src={require("../../images/ex-icon-03.png")} alt="" /></div>
                                <div className='txt-wrap'>
                                    <h4>Breast Cancer</h4>
                                    <p>Carcinoma antigen 15-3 (CA15-3), vascular endothelial growth factor (VEGF), epidermal growth factor (EGF),<br/>
                                        carcinoembryonic antigen, miRNA-10a, miRNA-10b </p>
                                </div>
                            </li>
                            <li>
                                <div className='img-wrap'><img src={require("../../images/ex-icon-04.png")} alt="" /></div>
                                <div className='txt-wrap'>
                                    <h4>Lung Cancer</h4>
                                    <p>Human telomerase reverse transcriptase (hTERT), EGFR</p>
                                </div>
                            </li>
                            <li>
                                <div className='img-wrap'><img src={require("../../images/ex-icon-05.png")} alt="" /></div>
                                <div className='txt-wrap'>
                                    <h4>Prostate Cancer</h4>
                                    <p>miRNA-21, miRNA-141 </p>
                                </div>
                            </li>
                        </ul>
                        <div className='bt-le'>
                            <p>Development of Diagnostic Technology Using Genetic Information in Blood and Saliva</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpandabilityEn;