import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AboutUsMap from '../sub-map/AboutUsMap';

function AboutUsEn() {
    return (
        <div className='AboutUs introduce target'>
            <AboutUsMap tag2={"Company Profile"} tag3={"Mission & Vision"}></AboutUsMap>
            {/* <div className='mb-sub-menu container'>
                <ul>
                    <li className='now'><Link to={"/AboutUs"}>BIO3S 목표</Link></li>
                    <li><Link to={"/AboutUs/outline"}>기업개요</Link></li>
                    <li><Link to={"/AboutUs/management"}>경영진 소개</Link></li>
                    <li><Link to={"/AboutUs/Cooperation"}>회사조직 및 협력기관</Link></li>
                </ul>
            </div> */}
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container'>
                        <div className='img-wrap'>
                            <img className='pc' src={require("../../enImages/mission-1@3x.png")} alt="" />
                            <img className='mb' src={require("../../enImages/mb-img/mission-m-1.png")} alt="" />
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <div className='img-wrap'>
                            <img className='pc' src={require("../../enImages/mission-2.png")} alt="" />
                            <img className='mb' src={require("../../enImages/mb-img/mission-m-2.png")} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsEn;