import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ResearchMap from "../sub-map/ResearchMap"

function Virus() {
    return (
        <div className='research result Virus'>
            <ResearchMap tag2={"연구성과"} tag3={"바이러스 연구관련 지적재산권"}></ResearchMap>
            <div className='sub-con'>
                <div className='container'>
                    <h3 className='h3-st1'>바이러스 연구관련 지적재산권 보유 현황</h3>
                    <div className='scroll-wrap'>
                        <div className='table-wrap'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>연번</th>
                                        <th>발명의 명칭</th>
                                        <th>보유기관</th>
                                        <th>출원/등록번호</th>
                                        <th>지식재산 기술현황</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                        <td>1</td>
                                        <td>범용성 바이러스 포획 단백질 및 이의 제조방법</td>
                                        <td>(주)바이오쓰리에스</td>
                                        <td>10-1973388</td>
                                        <td>특허등록 (2019.04.23)</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>코로나 바이러스 중화용 조성물</td>
                                        <td>(주)바이오쓰리에스</td>
                                        <td>10-2020-0062657</td>
                                        <td>특허출원 (2020.05.25)</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>바이러스 처리용 조성물</td>
                                        <td>(주)바이오쓰리에스</td>
                                        <td>10-2021-0100909</td>
                                        <td>특허출원 (2021.07.30)</td>
                                    </tr>
                                    <tr className='bg-cl'>
                                        <td>4</td>
                                        <td>단일단계 신속항원 진단검사를 위한 검체 수집용 용액</td>
                                        <td>(주)바이오쓰리에스</td>
                                        <td>10-2538916</td>
                                        <td>특허등록 (2023.05.26)</td>
                                    </tr>
                                    <tr className='bg-cl'>
                                        <td>5</td>
                                        <td>단일단계 신속항원 진단검사를 위한 검체 수집용 용액</td>
                                        <td>(주)바이오쓰리에스</td>
                                        <td>PCT/KR2022/007703</td>
                                        <td>특허출원 (2022.05.31)</td>
                                    </tr>
                                    <tr className='bg-cl'>
                                        <td>6</td>
                                        <td>단일단계 신속항원 진단검사를 위한 검체 수집용 용액</td>
                                        <td>(주)바이오쓰리에스</td>
                                        <td>17/855,452</td>
                                        <td>특허출원 (2022.06.30)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}




export default Virus;