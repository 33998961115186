import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PRMap from "../sub-map/PRMap"
import 'swiper/css';
import Parser from 'html-react-parser'
// API
import axios from 'axios';

function Notice() {
    let [data, setData] = useState();
    let [searchText, setSearchText] = useState('');
    let navigate = useNavigate()

    function search() {
        console.log(searchText);
        axios.get('https://api.bio3s.com/api/search?type=news&keyword=' + searchText )
            .then(res => {
                setData(res.data.data.boards);
            }).catch(() => {console.log("실패");});
    };

    useEffect(()=>{
        axios.get("https://api.bio3s.com/api/news").then((res) => {
            setData(res.data.data.news);
        }).catch(() => {console.log("실패");});
    },[]);

    console.log(data)

    function paginationClick(url) {
        console.log(url);

        axios.get(url + "&type=news" + "&keyword=" + searchText ).then((res) => {
            console.log(res)
            setData(res.data.data.news);
        }).catch((err) => {
            console.log(err);
        });
    };

    // 태그 지우기
    function contentReplace(content) {
        var text = content;
        return text.replace(/(<([^>]+)>)/ig,"");
    }

    return (
        <div className='PR News'>
            <PRMap tag2={"회사소식"} tag3={"기업뉴스"}></PRMap>
            <div className='sub-con'>
                <div className='container'>
                    <ul className='tab-wrap'>
                        <li className='now'>기업뉴스</li>
                        <li onClick={()=> navigate("/PR/Notice") }>자료실</li>
                    </ul>
                    <div className='list-top'>
                        <h3 className='h3-st1'>News</h3>
                        <div className='search-wrap'>
                            <input type="text" id='search' placeholder='검색어를 입력하세요' onKeyUp={()=>{if(window.event.keyCode==13){search()}}} onChange={()=>{
                                setSearchText(document.getElementById('search').value);
                                console.log(searchText);
                            }} />
                            <button onClick={() => search()}><i className='xi-search'></i></button>
                        </div>
                    </div>
                    <ul className='News-list'>
                        {
                            data ?
                            data.data.length == 0 ?
                                <div className='noData-wrap'>
                                    <div className='noData-img'>
                                        <img src={require("../../images/noData.png")} alt="" />
                                    </div>
                                </div>
                            :
                            data.data.map((item, i)=> {
                                return(
                                    <li key={i}>
                                        <div className='date'>
                                            <span className='day'>{item.created_at.substr(0, 10).substr(8, 2)}</span>
                                            <span className='year'>{item.created_at.substr(0, 7)}</span>
                                        </div>
                                        {
                                            item.status == 1 ?
                                                <div className='title-wrap notify'>
                                                    <div className='txt-wrap'>
                                                        <p onClick={()=>{navigate("/PR/NewsDetail/" + item.id)}} className='title'>{item.title}</p>
                                                        <p className='con'>{contentReplace(item.content)}</p>
                                                    </div>
                                                </div>
                                            :   <div className='title-wrap'>
                                                    <div className='txt-wrap'>
                                                        <p onClick={()=>{navigate("/PR/NewsDetail/" + item.id)}} className='title'>{item.title}</p>
                                                        <p className='con'>{contentReplace(item.content)}</p>
                                                    </div>
                                                </div>
                                        }
                                        <div className='hit'>
                                            <div className='mb-date'>{item.created_at.substr(0, 10)}</div>
                                            <i className='xi-eye-o'></i>
                                            <span className='num'>{item.count}</span>
                                            <button onClick={()=>{navigate("/PR/NewsDetail/" + item.id)}} className='plus'></button>
                                        </div>
                                    </li>
                                )
                            })
                            : null
                        }
                    </ul>
                    <ul className='Pagination'>
                        {
                            data ?
                            data.links.map((item, i)=>{
                                if(item.label == '&laquo; Previous'){
                                    if (item.url != null) {
                                        return(
                                            <li key={i} className='prev' onClick={() => paginationClick(item.url)}>
                                                <i className='xi-angle-left-thin'></i>
                                            </li>
                                        )
                                    }else{
                                        return(
                                            <li key={i} className='prev ev-no' onClick={() => paginationClick(item.url)}>
                                                <i className='xi-angle-left-thin'></i>
                                            </li>
                                        )
                                    }
                                }else if(item.label == 'Next &raquo;'){
                                    if (item.url != null) {
                                        return(
                                            <li key={i} className='next' onClick={() => paginationClick(item.url)}>
                                                <i className='xi-angle-right-thin'></i>
                                            </li>
                                        )
                                    }else{
                                        return(
                                            <li key={i} className='next ev-no' onClick={() => paginationClick(item.url)}>
                                                <i className='xi-angle-right-thin'></i>
                                            </li>
                                        )
                                    }
                                }else{
                                    if(data.current_page == item.label){
                                        return(
                                            <li key={i} className="now" onClick={() => paginationClick(item.url)}>{item.label}</li>
                                        )
                                    }else{
                                        return(
                                            <li key={i} onClick={() => paginationClick(item.url)}>{item.label}</li>
                                        )
                                    }
                                }
                            })
                            : null
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Notice;