import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ResearchMap from "../sub-map/ResearchMap"

function Expandability() {
    return (
        <div className='research ovc Expandability'>
            <ResearchMap tag2={"OVC Technology"} tag3={"확장성"}></ResearchMap>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container'>
                        <h3 className='h3-st1'>확장 가능 진단분야<span>미국의 상용 타액(구강점막) 검진 키트의 예</span></h3>
                        <ul className='flex-wrap'>
                            <li>
                                <h4><span>01</span>HIV</h4>
                                <p className='txt-ct'>HIV 항체, 확인용 HIV 항체</p>
                                <p>작두콩 추출물에 존재하는 Tcan(truncated canavalin)은 SARS-CoV-2와 결합하며 
                                    4-8개의 올리고머를 형성하여 상당히 강한 힘으로 SARS-CoV-2와 결합하며 
                                    70도까지도 안정된 구조를 보임을 확인함.</p>
                            </li>
                            <li>
                                <h4><span>02</span>HCV</h4>
                                <p className='txt-ct'>HCV 항체</p>
                                <p>작두콩 유래 천연단백질은 작두콩을 식품용균주를 활용하여 추출한 원료로
                                    세포 및 호흡기 <span>독성(기도내 점적투여)이 없음을 안전성평가연구소에서 확인함.</span></p>
                            </li>
                            <li>
                                <h4><span>03</span>물질남용</h4>
                                <p className='txt-ct'>NIDA 5 혈액패널 + barbiturates, (meth)amphetamines, 
                                    benzodiazepines, methadone, cocaine, opiates, 
                                    marijuana, phencyclidine</p>
                                <p>hACE2가 표면에 코팅된 ELISA를 이용하여 결합된 SARS-CoV-2의
                                    RBD의 탈리효과가 우수함을 확인함.</p>
                            </li>
                            <li>
                                <h4><span>04</span>혈중 알코올</h4>
                                <p className='txt-ct'>에탄올</p>
                                <p>Q.E.D. Saliva Alcohol Test</p>
                            </li>
                            <li>
                                <h4><span>05</span>호르몬</h4>
                                <p className='txt-ct'>Estradiol, progesterone, testosterone, DHEA, cortisol</p>
                                <p>ZRT Saliva Test</p>
                            </li>
                            <li>
                                <h4><span>06</span>보험/중독</h4>
                                <p className='txt-ct'>Cocaine metabolite, cotinine, cannabinoids, opiates, 
                                    phencyclidine </p>
                                <p>MICRO-PLATE EIA & AUTO-LYTE</p>
                            </li>
                        </ul>
                        <div className='bt-le'>
                            <p>혈액과 타액 속 유전정보를 이용한 진단기술 개발 동향. BRIC View 2017-T10</p>
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <h3 className='h3-st1'>확장 가능 진단분야<span>현재 타액에서 발견되는 암 관련 마커들</span></h3>
                        <ul className='list-wrap'>
                            <li>
                                <div className='img-wrap'><img src={require("../../images/ex-icon-01.png")} alt="" /></div>
                                <div className='txt-wrap'>
                                    <h4>두경부암</h4>
                                    <p>Interleukin-1 (IL-1), IL-1β, IL-6, IL-8 (단백질과 mRNA), tumor necrosis factor (TNF), transferrin, CD44, CD59,<br/>
                                        zinc finger protein 510 (ZFN510) peptide, endothelin-1 (ET-1), HNP-1, cytokeratin-19 조각 (CYFRA-21-1) ORAOV1 관련 DNA 조각,<br/>
                                        miRNA-200a, mtDNA (Cox I, II), lncRNA (HOTAIR, MALAT-1)</p>
                                </div>
                            </li>
                            <li>
                                <div className='img-wrap'><img src={require("../../images/ex-icon-02.png")} alt="" /></div>
                                <div className='txt-wrap'>
                                    <h4>췌장암</h4>
                                    <p>KRAS, MBD3L2, ACRV1, DPM1 mRNAs</p>
                                </div>
                            </li>
                            <li>
                                <div className='img-wrap'><img src={require("../../images/ex-icon-03.png")} alt="" /></div>
                                <div className='txt-wrap'>
                                    <h4>유방암</h4>
                                    <p>Carcinoma antigen 15-3 (CA15-3), vascular endothelial growth factor (VEGF), epidermal growth factor (EGF),<br/>
                                        carcinoembryonic antigen, miRNA-10a, miRNA-10b </p>
                                </div>
                            </li>
                            <li>
                                <div className='img-wrap'><img src={require("../../images/ex-icon-04.png")} alt="" /></div>
                                <div className='txt-wrap'>
                                    <h4>폐암</h4>
                                    <p>Human telomerase reverse transcriptase (hTERT), EGFR</p>
                                </div>
                            </li>
                            <li>
                                <div className='img-wrap'><img src={require("../../images/ex-icon-05.png")} alt="" /></div>
                                <div className='txt-wrap'>
                                    <h4>전립선암</h4>
                                    <p>miRNA-21, miRNA-141 </p>
                                </div>
                            </li>
                        </ul>
                        <div className='bt-le'>
                            <p>혈액과 타액 속 유전정보를 이용한 진단기술 개발 동향. BRIC View 2017-T10</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expandability;