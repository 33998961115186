import { createSlice } from '@reduxjs/toolkit'

let cart = createSlice({
    name : 'item' ,
    initialState : 
    [
        {id : 0, name : 'White and Black', count : 2},
        {id : 2, name : 'Grey Yordan', count : 1}
    ] ,
    reducers : {
        setCart(state , action){
            let idNum = state.findIndex((a) => a.id == action.payload);
            state[idNum].count += 1 ;
        },
        setCartAdd(state , action){
            state.push(action.payload);
        }
    }
})

export let { setCart, setCartAdd } = cart.actions 

export default cart ;