import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AboutUsMap from '../sub-map/AboutUsMap';

function ManagementEn() {
    let [menuHeight1, setMenuHeight1] = useState('');
    let [menuHeight2, setMenuHeight2] = useState('');
    let [menuHeight3, setMenuHeight3] = useState('');

    useEffect(() => {
        const options = {
            root: null, // viewport
            rootMargin: "500px",
            threshold: 1,  // 50%가 viewport에 들어와 있어야 callback 실행
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active');
                } else {
                    entry.target.classList.remove('active');
                }
            });
        }, options);

        const boxList = document.querySelectorAll('.box');
        console.log(boxList);

        boxList.forEach(el => observer.observe(el));
    })


    return (
        <div className='AboutUs introduce management en'>
            <AboutUsMap tag2={"Company Profile"} tag3={"CEO Greeting"}></AboutUsMap>
            <div className='mb-sub-menu container'>
                <ul>
                    <li><Link to={"/AboutUs"}>BIO3S 목표</Link></li>
                    <li><Link to={"/AboutUs/outline"}>기업개요</Link></li>
                    <li className='now'><Link to={"/AboutUs/management"}>경영진 소개</Link></li>
                    <li><Link to={"/AboutUs/Cooperation"}>회사조직 및 협력기관</Link></li>
                </ul>
            </div>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='content_container container'>
                        <h3 className='h3-st2'>CEO Greeting</h3>
                        <div className='ceo_banner_wrap section1'>
                            <div className='banner_txt_wrap'>
                                <p className='banner_txt'>CEO GREETING</p>
                            </div>
                            <h3 className='banner_tit'>
                                Our Promise with <br className='mbView' /><span className='point_1 point'>NORO-X Hand Soap</span> :<br />
                                Opening New Horizons <br className='mbView' />in <span className='point_2 point'>Disease Prevention</span>
                            </h3>
                            <img className='banner_img pcView' src={require("../../images/ceo_banner_img.webp")} />
                            <img className='banner_img mbView' src={require("../../images/mb-img/ceo_banner_img_m.webp")} />
                            <img className='ceo_photo' src={require("../../enImages/ceo_photo_en.webp")} alt="" />
                        </div>
                        <div className='main_txt_wrap'>
                            <h4 className='main_tit'>
                                Hello,<br className='mbView' /> I am Kim Duwoon, the CEO of BIO3S,<br className='mbView' /> a leading company in virus infection prevention.
                            </h4>
                            <p className='main_txt'>
                                I would like to introduce our relentless research and innovation efforts to protect us from viruses that threaten our daily lives and health.
                            </p>
                            <p className='main_txt'>
                                One of the lessons taught to us by the COVID-19 pandemic is the absolute importance of virus prevention. Through this period,<br className='pcView'/>
                                we have developed "NORO-X Foaming Hand Soap" using solutions found in nature and extracts from Job's tears.<br className='pcView'/>
                                This product has been recognized as an excellent product by the Public Procurement Service and has become an essential item in various public institutions<br className='pcView'/>
                                such as the Ministry of Food and Drug Safety, Sejong Government Complex, schools, and post offices.
                            </p>
                            <p className='main_txt'>
                                In 2023, BIO3S was designated as a local community contribution company. This is the result of our efforts in ESG management being recognized,<br className='pcView'/>
                                and we aim to share our material and intellectual resources for disease prevention generously with public institutions and socially vulnerable groups.
                            </p>
                            <p className='main_txt'>
                                Beyond simply selling products, BIO3S aims to provide prevention education for achieving zero infections in public institutions and to lead in addressing<br className='pcView'/>
                                the difficulties faced in public settings. Although it took six years of research and development to achieve certification for new technologies, new products,<br className='pcView'/>
                                excellent products, and innovative products, we are now more prepared than ever to create infection-free work environments in public institutions.
                            </p>
                            <p className='main_txt'>
                                Based on the virus infection prevention education content we have accumulated, we are willing to provide support in the form of volunteer contributions<br className='pcView'/>
                                if your institution requires infection prevention education. If you need education or counseling on disease prevention, please feel free to contact us anytime.
                            </p>
                            <p className='main_txt'>
                                BIO3S will be with you for a healthy society and safe daily life.
                            </p>
                            <p className='main_txt'>
                                Thank you sincerely for your interest and support.
                            </p>
                            <p className='main_txt'>
                                Sincerely,
                            </p>
                            <p className='main_txt_bottom main_txt'>
                                <strong>Duwoon Kim</strong><br />
                                CEO, BIO3S
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManagementEn;