import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AboutUsMap from "../sub-map/AboutUsMap";

function OutlineEn() {
  return (
    <div className="AboutUs introduce outline">
      <AboutUsMap
        tag2={"Company Profile"}
        tag3={"Our Business Model"}
      ></AboutUsMap>
      <div className="mb-sub-menu container">
        <ul>
          <li>
            <Link to={"/AboutUs"}>BIO3S 목표</Link>
          </li>
          <li className="now">
            <Link to={"/AboutUs/outline"}>기업개요</Link>
          </li>
          <li>
            <Link to={"/AboutUs/management"}>경영진 소개</Link>
          </li>
          <li>
            <Link to={"/AboutUs/Cooperation"}>회사조직 및 협력기관</Link>
          </li>
        </ul>
      </div>
      <div className="sub-con">
        <div className="section1">
          <div className="container">
            <div className="flex-wrap">
              <div className="img-wrap">
                <img
                  className="pc"
                  src={require("../../images/BUILDINGS.png")}
                  alt=""
                />
                <img
                  className="mb"
                  src={require("../../images/mb-img/BUILDINGS.png")}
                  alt=""
                />
              </div>
              <div className="con-box">
                <div>
                  <h3 className="scd">Company Overview</h3>
                  <div className="list-wrap">
                    <ul className="left-ul">
                      <li>
                        <span>
                          Corporate <br /> Legal Title
                        </span>
                        <p>BIO3S Co., Ltd.</p>
                      </li>
                      <li>
                        <span>CEO</span>
                        <p>Duwoon Kim</p>
                      </li>
                      <li>
                        <span>
                          Date of <br /> Incorporation
                        </span>
                        <p>October 18, 2016</p>
                      </li>
                    </ul>
                    <ul className="right-ul">
                      <li>
                        <span className="letter">
                          Corporate <br /> Registration Number
                        </span>
                        <p>200111-0475339</p>
                      </li>
                      <li>
                        <span className="letter">
                          Business <br /> Registration Number
                        </span>
                        <p>372-87-00681</p>
                      </li>
                      <li>
                        <span className="letter">
                          Representative <br /> Phone Number
                        </span>
                        <p>1899-5397</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <h3 className="h3-st2">Locations</h3>
            <ul>
              <li>
                <img src={require("../../images/com-icon-01.png")} alt="" />
                <p className="txt-top">
                  Headquarters and Biomaterial Production Facility
                </p>
                <p className="txt-ctf">
                  102, Agribusiness Incubator Center, 77 Yongbongro, Bukgu,
                  Gwangju, Republic of Korea
                </p>
                <button
                  onClick={() =>
                    window.open(
                      "https://map.naver.com/p/search/%EC%A0%84%EB%82%A8%EB%8C%80%ED%95%99%EA%B5%90%EB%86%8D%EC%97%85%EC%A0%84%EB%AC%B8%EC%B0%BD%EC%97%85%EB%B3%B4%EC%9C%A1%EC%84%BC%ED%84%B0?c=15.00,0,0,0,dh",
                      "_blank"
                    )
                  }
                >
                  View Map
                </button>
              </li>
              <li>
                <img src={require("../../images/com-icon-03.png")} alt="" />
                <p className="txt-top">
                  Over-the-counter (OTC)
                  <br />
                  pharmaceutical sales business <br />/ Cosmetics manufacturing
                  facility
                </p>
                <p className="txt-cts">
                  30, Cheomdan Venturesoro 62Beongil, Bukgu, Gwangju, Republic
                  of Korea
                </p>
                <button
                  onClick={() =>
                    window.open(
                      "https://map.naver.com/v5/search/%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C%2062%EB%B2%88%EA%B8%B8%2030/address/14122376.212750759,4195377.973604299,%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C62%EB%B2%88%EA%B8%B8%2030,new?c=14122348.7057046,4195378.6413890,19,0,0,0,dh&isCorrectAnswer=true",
                      "_blank"
                    )
                  }
                >
                  View Map
                </button>
              </li>
              <li>
                <img src={require("../../images/com-icon-05.png")} alt="" />
                <p className="txt-top">Medical device sales</p>
                <p className="txt-ctt">
                  1, Yongbong-ro, Buk-gu
                  <br />
                  Gwangju, Republic of Korea
                </p>
                <button
                  onClick={() =>
                    window.open(
                      "https://map.naver.com/p/search/%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%9A%A9%EB%B4%89%EB%A1%9C%201%20%EC%9A%A9%EB%B9%84%EB%B9%8C%EB%94%A9/place/1502240149?c=15.00,0,0,0,dh&isCorrectAnswer=true",
                      "_blank"
                    )
                  }
                >
                  View Map
                </button>
              </li>

              <li>
                <img src={require("../../images/com-icon-04.png")} alt="" />
                <p className="txt-top">Corporate R&D Center</p>
                <p className="txt-ctf">
                  30, Cheomdan venture so-ro 62beon-gil, Buk-gu, Gwangju,
                  <br /> Republic of Korea
                </p>
                <p className="txt-ctf">Gwahak-ro, Yuseong-gu, Daejeon</p>
                <div className="buttonSection">
                  <button
                    onClick={() =>
                      window.open(
                        "https://map.naver.com/p/search/%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C%2062%EB%B2%88%EA%B8%B8%2030/address/14122376.2127508,4195377.9736043,%EA%B4%91%EC%A3%BC%EA%B4%91%EC%97%AD%EC%8B%9C%20%EB%B6%81%EA%B5%AC%20%EC%B2%A8%EB%8B%A8%EB%B2%A4%EC%B2%98%EC%86%8C%EB%A1%9C62%EB%B2%88%EA%B8%B8%2030,new?c=16.33,0,0,0,dh&isCorrectAnswer=true",
                        "_blank"
                      )
                    }
                  >
                    View Map
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://map.naver.com/v5/search/%EB%8C%80%EC%A0%84%EA%B4%91%EC%97%AD%EC%8B%9C%20%EC%9C%A0%EC%84%B1%EA%B5%AC%20%EA%B3%BC%ED%95%99%EB%A1%9C%20%ED%95%9C%EA%B5%AD%EA%B8%B0%EC%B4%88%EA%B3%BC%ED%95%99%EC%A7%80%EC%9B%90%EC%97%B0%EA%B5%AC%EC%9B%90/place/13188347?c=14176538.2879822,4351971.8024034,15,0,0,0,dh",
                        "_blank"
                      )
                    }
                  >
                    View Map
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="section3">
          <div className="container">
            <h3 className="h3-st1">Business Vision</h3>
            <div className="flax-wrap">
              <div className="flax-box">
                <img src={require("../../images/com-le.jpg")} alt="" />
                <h4 className="scd">
                  Current Business<span>2016 ~</span>
                </h4>
                <ul>
                  <li>Hygiene Products Market</li>
                  <li>Biomaterials Market</li>
                  <li>OTC Products Market</li>
                </ul>
              </div>
              <div className="flax-box">
                <img src={require("../../images/com-ri.jpg")} alt="" />
                <h4 className="scd">
                  Next Generation Business<span>2022 ~</span>
                </h4>
                <ul>
                  <li>
                    Registration of new medical devices for oral specimen
                    collection
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section4">
          <div className="img-wrap">
            <img
              className="pc"
              src={require("../../enImages/model.png")}
              alt=""
            />
            <img
              className="mb"
              src={require("../../enImages/mb-img/model-m.png")}
              alt=""
            />
          </div>
        </div>
        <div className="section5">
          <div className="container">
            <div className="img-wrap">
              <img
                className="pc"
                src={require("../../enImages/core-biz.png")}
                alt=""
              />
              <img
                className="mb"
                src={require("../../enImages/mb-img/core-biz-m.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OutlineEn;
