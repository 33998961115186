import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function PersonalNformation() {

    return (
        <div className="policy">
            <div className="container">
                <p>
                    (주)바이오쓰리에스('www.bio3s.com'이하 '바이오쓰리에스')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다. <br />
                    <br />
                    ○ 이 개인정보처리방침은 2022년 10월 1부터 적용됩니다.<br />
                    <br />
                    <br />
                    제1조(개인정보의 처리 목적)<br />
                    <br />
                    (주)바이오쓰리에스('www.bio3s.com'이하 '바이오쓰리에스')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br />
                    <br />
                    1. 홈페이지 회원가입 및 관리<br />
                    <br />
                    각종 고지·통지 목적으로 개인정보를 처리합니다.<br />
                    <br />
                    <br />
                    2. 민원사무 처리<br />
                    <br />
                    민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.<br />
                    <br />
                    <br />
                    3. 재화 또는 서비스 제공<br />
                    <br />
                    물품배송, 계약서·청구서 발송, 맞춤서비스 제공, 요금결제·정산을 목적으로 개인정보를 처리합니다.<br />
                    <br />
                    <br />
                    <br />
                    <br />
                    제2조(개인정보의 처리 및 보유 기간)<br />
                    <br />
                    ① (주)바이오쓰리에스은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br />
                    <br />
                    ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br />
                    <br />
                    1.민원사무 처리<br />
                    민원사무 처리 와 관련한 개인정보는 수집.이용에 관한 동의일로부터 5년 까지 위 이용목적을 위하여 보유.이용됩니다.<br />
                    보유근거 : 민원 후속 처리<br />
                    관련법령 : 1.소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br />
                    2. 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br />
                    예외사유 :<br />
                    <br />
                    <br />
                    제3조(처리하는 개인정보의 항목)<br />
                    <br />
                    ① (주)바이오쓰리에스은(는) 다음의 개인정보 항목을 처리하고 있습니다.<br />
                    <br />
                    1 재화 또는 서비스 제공 <br />
                    필수항목 : 이메일, 휴대전화번호, 이름, 회사명<br />
                    선택항목 : 성별, 이름<br />
                    <br />
                    <br />
                    제4조(개인정보의 파기절차 및 파기방법)<br />
                    <br />
                    <br />
                    ① (주)바이오쓰리에스 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br />
                    <br />
                    ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<br />
                    1. 법령 근거 :<br />
                    2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜<br />
                    <br />
                    ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br />
                    1. 파기절차<br />
                    (주)바이오쓰리에스 은(는) 파기 사유가 발생한 개인정보를 선정하고, (주)바이오쓰리에스 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br />
                    <br />
                    2. 파기방법<br />
                    <br />
                    전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.<br />
                    <br />
                    종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다<br />
                    <br />
                    <br />
                    제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)<br />
                    <br />
                    <br />
                    <br />
                    ① 정보주체는 (주)바이오쓰리에스에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.<br />
                    <br />
                    ② 제1항에 따른 권리 행사는(주)바이오쓰리에스에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주)바이오쓰리에스은(는) 이에 대해 지체 없이 조치하겠습니다.<br />
                    <br />
                    ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br />
                    <br />
                    ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.<br />
                    <br />
                    ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br />
                    <br />
                    ⑥ (주)바이오쓰리에스은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br />
                    <br />
                    <br />
                    <br />
                    제6조(개인정보의 안전성 확보조치에 관한 사항)<br />
                    <br />
                    (주)바이오쓰리에스은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.<br />
                    <br />
                    1. 정기적인 자체 감사 실시<br />
                    개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.<br />
                    <br />
                    2. 개인정보 취급 직원의 최소화 및 교육<br />
                    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br />
                    <br />
                    3. 개인정보에 대한 접근 제한<br />
                    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br />
                    <br />
                    4. 문서보안을 위한 잠금장치 사용<br />
                    개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br />
                    <br />
                    <br />
                    <br />
                    <br />
                    제7조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)<br />
                    <br />
                    <br />
                    <br />
                    (주)바이오쓰리에스 은(는) 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.<br />
                    <br />
                    <br />
                    제8조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)<br />
                    <br />
                    <br />
                    <br />
                    행태정보의 수집·이용·제공 및 거부등에 관한 사항<br />
                    <br />
                    개인정보처리자명 은(는) 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지 않습니다.<br />
                    <br />
                    <br />
                    <br />
                    제9조(추가적인 이용·제공 판단기준)<br />
                    <br />
                    (주)바이오쓰리에스 은(는) ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다.<br />
                    이에 따라 (주)바이오쓰리에스 가(이) 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.<br />
                    ▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부<br />
                    <br />
                    ▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부<br />
                    <br />
                    ▶ 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부<br />
                    <br />
                    ▶ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부<br />
                    <br />
                    ※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성·공개함<br />
                    <br />
                    <br />
                    <br />
                    제10조(가명정보를 처리하는 경우 가명정보 처리에 관한 사항)<br />
                    <br />
                    <br />
                    제11조 (개인정보 보호책임자에 관한 사항)<br />
                    <br />
                    ① (주)바이오쓰리에스 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br />
                    <br />
                    ▶ 개인정보 보호책임자<br />
                    성명 :조종연<br />
                    직책 :부사장<br />
                    직급 :부사장<br />
                    연락처 :010-9230-8234, jycho@bio3s.com,<br />
                    ※ 개인정보 보호 담당부서로 연결됩니다.<br />
                    <br />
                    <br />
                    ▶ 개인정보 보호 담당부서<br />
                    부서명 :<br />
                    담당자 :<br />
                    연락처 :, ,<br />
                    ② 정보주체께서는 (주)바이오쓰리에스 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주)바이오쓰리에스 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br />
                    <br />
                    <br />
                    <br />
                    제12조(국내대리인의 지정)<br />
                    <br />
                    정보주체는 ｢개인정보 보호법｣ 제39조의11에 따라 지정된 (주)바이오쓰리에스의 국내대리인에게 개인정보 관련 고충처리 등의 업무를 위하여 연락을 취할 수 있습니다. (주)바이오쓰리에스은(는) 정보주체의 개인정보 관련 고충처리 등 개인정보 보호책임자의 업무 등을 신속하게 처리할 수 있도록 노력하겠습니다.<br />
                    <br />
                    <br />
                    제13조(개인정보의 열람청구를 접수·처리하는 부서)<br />
                    정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.<br />
                    (주)바이오쓰리에스은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br />
                    <br />
                    ▶ 개인정보 열람청구 접수·처리 부서<br />
                    부서명 : 행정부<br />
                    담당자 : 조민아<br />
                    연락처 : 010-5525-9757, mina@bio3s.com,<br />
                    <br />
                    <br />
                    제14조(정보주체의 권익침해에 대한 구제방법)<br />
                    <br />
                    <br />
                    <br />
                    정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br />
                    <br />
                    1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br />
                    2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br />
                    3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br />
                    4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)<br />
                    <br />
                    「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br />
                    <br />
                    ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.<br />
                    <br />
                    제15조(영상정보처리기기 운영·관리에 관한 사항)<br />
                    ① (주)바이오쓰리에스은(는) 아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.<br />
                    <br />
                    1.영상정보처리기기 설치근거·목적 : (주)바이오쓰리에스의<br />
                    <br />
                    2.설치 대수, 설치 위치, 촬영 범위 :<br />
                    설치대수 : 4 대<br />
                    설치위치 : 식품첨가물 공장 내외부 2, 제품 창고 외부 2<br />
                    촬영범위 : 공장 내부 및 제품 창고 외부<br />
                    3.관리책임자, 담당부서 및 영상정보에 대한 접근권한자 : 조종연 부사장, 접근 권한자: 경영진 및 행정부<br />
                    <br />
                    4.영상정보 촬영시간, 보관기간, 보관장소, 처리방법<br />
                    촬영시간 : 24 시간<br />
                    보관기간 : 촬영시부터 1년<br />
                    보관장소 및 처리방법 : NAS 저장 장치<br />
                    5.영상정보 확인 방법 및 장소 : 로그인 가능한 휴대폰 및 컴퓨터<br />
                    <br />
                    6.정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람.존재확인 청구서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명.신체.재산 이익을 위해 필요한 경우에 한해 열람을 허용함<br />
                    <br />
                    7.영상정보 보호를 위한 기술적.관리적.물리적 조치 :<br />
                    <br />
                    <br />
                    <br />
                    제16조(개인정보 처리방침 변경)<br />
                    <br />
                    <br />
                    ① 이 개인정보처리방침은 2022년 10월 1부터 적용됩니다. <br />
                </p>
            </div>
        </div>
    )
}


export default PersonalNformation;