import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ProducthMap({tag2 , tag3}) {
    let [ menuHeight1, setMenuHeight1] = useState('');
    let [ menuHeight2, setMenuHeight2] = useState('');
    let [ menuHeight3, setMenuHeight3] = useState('');

    return (
        <div className='sub-top'>
            <div className='sub-banner'>
                <div className='banner-img'></div>
                <h2>PRODUCT</h2>
                <p>바이러스 팬데믹 대비 바이오 메디컬 기업</p>
            </div>
            <div className='sub-map'>
                <div className='container'>
                    <Link to={'/'} className='home-btn'><i className='xi-home'></i></Link>
                    <div className={'sub-menu ' + menuHeight1}>
                        <div className='show' onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>
                            <p>PRODUCT</p>
                            <i className='xi-angle-down'></i>
                        </div>
                        <ul className='sub-menu-list'>
                            <li><Link to={"/AboutUs"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>About us</Link></li>
                            <li><Link to={"/Research"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>R&D</Link></li>
                            <li><Link to={"/Product"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>PRODUCT</Link></li>
                            <li><Link to={"/Procurement"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>공공조달</Link></li>
                            <li><Link to={"/PR"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>MEDIA</Link></li>
                            <li><Link to={"/Contact"} onClick={()=>{ menuHeight1 ? setMenuHeight1('') : setMenuHeight1('open') }}>CONTACT US</Link></li>
                        </ul>
                    </div>
                    <div className={'sub-menu ' + menuHeight2}>
                        <div className='show' onClick={()=>{ menuHeight2 ? setMenuHeight2('') : setMenuHeight2('open') }}>
                            <p>{tag2}</p>
                            <i className='xi-angle-down'></i>
                        </div>
                        <ul className='sub-menu-list'>
                            <li><Link to={"/Product"} onClick={()=>{ menuHeight2 ? setMenuHeight2('') : setMenuHeight2('open') }}>Clean Tech</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProducthMap;