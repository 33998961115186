import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PRMap from "../sub-map/PRMap"
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
// API
import axios from 'axios';

function SNSEn() {
    const swiperRef1 = useRef();
    let [ addNumber, setAddNumber ] = useState(12);
    let [ btn, setBtn ] = useState("");
    let [instagram, setInstagram] = useState();
    let [youtube, setYoutube] = useState();
    
    useEffect(()=>{
        axios.get("https://api.bio3s.com/api/en/sns").then((res) => {
            setInstagram(res.data.data.instagram);
            setYoutube(res.data.data.youtube);
        }).catch(() => {console.log("실패");});
    },[]);

    useEffect(()=>{
        if(instagram != null){
            const count = Object.keys(instagram).length;
            if ( count <= addNumber ) {
                setBtn(" dp-none")
            }
        }
    });

    console.log(youtube);
    console.log(instagram);

    return (
        <div className='PR SNS'>
            <PRMap tag2={"SNS"}></PRMap>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container'>
                        <h3 className='h3-st1'>YouTube</h3>
                        <div className='slide-wrap'>
                            <Swiper
                            pagination={true}
                            modules={[Pagination]}
                            onSwiper={(swiper) => {
                                swiperRef1.current = swiper;
                            }}
                            spaceBetween={20}
                            slidesPerView={1}
                            loop={false}
                            breakpoints={{
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40
                                },
                            }}
                            >
                                {
                                    youtube ?
                                    youtube.map((item, i)=>{
                                        return(
                                            <SwiperSlide key={i}>
                                                <div className='thumbnail' onClick={()=> window.open(item.link, '_blank')} >
                                                    <img src={item.fileAddr} alt="" />
                                                    <i className='xi-play-circle-o'></i>
                                                </div>
                                                <p onClick={()=> window.open(item.link, '_blank')}>{item.title}</p>
                                            </SwiperSlide>
                                        )
                                    })
                                    : null
                                }
                            </Swiper>
                            <button className='slidePrev slideBtn' onClick={() => swiperRef1.current.slidePrev()}><i className='xi-long-arrow-left'></i></button>
                            <button className='slideNext slideBtn' onClick={() => swiperRef1.current.slideNext()}><i className='xi-long-arrow-right'></i></button>
                        </div>
                    </div>
                </div>
                <div className='section2'>
                    <div className='container'>
                        <h3 className='h3-st1'>Instagram</h3>
                        <ul className='Instagram-list'>
                            {
                                instagram ?
                                instagram.map((item, i)=>{
                                    if ( i < addNumber ) {
                                        return(
                                            <li key={i}>
                                                <div className='img-wrap' onClick={()=> window.open(item.link, '_blank')}>
                                                    <img src={item.fileAddr} alt="" />
                                                </div>
                                                <div className='title-wrap'>
                                                    <p onClick={()=> window.open(item.link, '_blank')}>{item.title}</p>
                                                </div>
                                            </li>
                                        )
                                    }
                                })
                                : null
                            }
                        </ul>
                        <button className={'btn-st1' + btn} onClick={()=>{setAddNumber(addNumber + 12)}}>더보기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SNSEn;