import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ResearchMap from "../sub-map/ResearchMap"
import ReactPlayer from 'react-player/lazy';

function ResearchEn() {
    return (
        <div className='research ovc ovc-picture'>
            <ResearchMap tag2={"OVC Technology"} tag3={"About OVC Technology"}></ResearchMap>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='container'>
                        <div className='flex-wrap'>
                            <div className='txt-wrap'>
                                <div className='txt-ct'>
                                    <p className='scd'>BIO3S OVC™ Technology</p>
                                </div>
                            </div>
                            <div className='player-wrapper'>
                                <ReactPlayer
                                    className='react-player'
                                    url={'https://www.youtube.com/watch?v=PmT-sP2-GoQ&t=2s'}    // 플레이어 url    // 플레이어 크기 (세로)
                                    playing={true}        // 자동 재생 on
                                    muted={true}          // 자동 재생 on
                                    controls={true}       // 플레이어 컨트롤 노출 여부
                                    light={false}         // 플레이어 모드
                                    pip={true}            // pip 모드 설정 여부
                                    onEnded={() => {}}  // 플레이어 끝났을 때 이벤트
                                    width={100 + "%"}
                                    height={100 + "%"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResearchEn;