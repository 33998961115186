// fullpage css
import 'fullpage.js/dist/jquery.fullpage.min.css';
// slick css
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import axios from 'axios';

// header, footer
import Header from './components/Header'
import Footer from './components/Footer'

// ================페이지===========================
import KoMould from './components/KoMould';
import Main from './pages/Main';
// AboutUs
import AboutUs from "./pages/AboutUs/AboutUs";
import Outline from "./pages/AboutUs/Outline";
import Management from "./pages/AboutUs/Management";
import Cooperation from "./pages/AboutUs/Cooperation";
import History from "./pages/AboutUs/History";
import Finance from "./pages/AboutUs/Finance";
// R&D
import Research from "./pages/Research/Research";
import Material from "./pages/Research/Material";
import Exam from "./pages/Research/Exam";
import Expandability from "./pages/Research/Expandability";
import Patent from "./pages/Research/Patent";
import Virus from "./pages/Research/Virus";
// Product
import Product from "./pages/Product/Product";
import ProductDetail from "./pages/Product/ProductDetail";
// procurement
import Procurement from "./pages/Procurement/Procurement";
import ProcurementDetail from "./pages/Procurement/ProcurementDetail";
// PR
import PR from "./pages/PR/PR";
import Notice from "./pages/PR/Notice";
import NoticeDetail from "./pages/PR/NoticeDetail";
import News from "./pages/PR/News";
import NewsDetail from "./pages/PR/NewsDetail";
import SNS from "./pages/PR/SNS";
// Contact
import Contact from "./pages/Contact/Contact";
import Directions from "./pages/Contact/Directions";
// PersonalNformation
import PersonalNformation from "./pages/PersonalNformation";

// ================영문페이지===========================
import EnMould from './enComponents/EnMould';
import MainEn from './enPages/Main';
// AboutUs
import AboutUsEn from "./enPages/AboutUs/AboutUs";
import OutlineEn from "./enPages/AboutUs/Outline";
import ManagementEn from "./enPages/AboutUs/Management";
import CooperationEn from "./enPages/AboutUs/Cooperation";
import HistoryEn from "./enPages/AboutUs/History";
import FinanceEn from "./enPages/AboutUs/Finance";
// R&D
import ResearchEn from "./enPages/Research/Research";
import MaterialEn from "./enPages/Research/Material";
import ExamEn from "./enPages/Research/Exam";
import ExpandabilityEn from "./enPages/Research/Expandability";
import PatentEn from "./enPages/Research/Patent";
import VirusEn from "./enPages/Research/Virus";
// Product
import ProductEn from "./enPages/Product/Product";
import ProductDetailEn from "./enPages/Product/ProductDetail";
// procurement
import ProcurementEn from "./enPages/Procurement/Procurement";
import ProcurementDetailEn from "./enPages/Procurement/ProcurementDetail";
// PR
import PREn from "./enPages/PR/PR";
import NoticeEn from "./enPages/PR/Notice";
import NoticeDetailEn from "./enPages/PR/NoticeDetail";
import NewsEn from "./enPages/PR/News";
import NewsDetailEn from "./enPages/PR/NewsDetail";
import SNSEn from "./enPages/PR/SNS";
// Contact
import ContactEn from "./enPages/Contact/Contact";
import DirectionsEn from "./enPages/Contact/Directions";
// PersonalNformation
import PersonalNformationEn from "./enPages/PersonalNformation";


// 데이터
import { data } from './date';

// let obj = { name : 'kim' };
// localStorage.setItem( 'data', JSON.stringify(obj) );
// let josnObj = JSON.parse(localStorage.getItem('data'));
// console.log(josnObj);

function App() {
  let [shose ,setShose] = useState(data);
  let navigate = useNavigate();

  useEffect(() => {
    // if (JSON.parse(localStorage.getItem('watched')) == '') {
    //   localStorage.setItem( 'watched' , JSON.stringify( [] ) )
    // }
  }, [])

  return (
    <div className="App">
      {/* body */}
      <Routes>
        <Route path='/' element={<KoMould/>}>
          {/* 메인 */}
          <Route path='/' element={<Main/>}></Route>
          {/* About */}
          <Route path='/AboutUs' element={<AboutUs/>}></Route>
          <Route path='/AboutUs/outline' element={<Outline/>}></Route>
          <Route path='/AboutUs/management' element={<Management/>}></Route>
          <Route path='/AboutUs/cooperation' element={<Cooperation/>}></Route>
          <Route path='/AboutUs/history' element={<History/>}></Route>
          <Route path='/AboutUs/finance' element={<Finance/>}></Route>
          {/* R&D */}
          <Route path='/Research' element={<Research/>}></Route>
          <Route path='/Research/material' element={<Material/>}></Route>
          <Route path='/Research/Exam' element={<Exam/>}></Route>
          <Route path='/Research/Expandability' element={<Expandability/>}></Route>
          <Route path='/Research/Patent' element={<Patent/>}></Route>
          <Route path='/Research/Virus' element={<Virus/>}></Route>
          {/* Product */}
          <Route path='/Product' element={<Product/>}></Route>
          <Route path='/Product/ProductDetail/:id' element={<ProductDetail/>}></Route>
          {/* 공공조달 */}
          <Route path='/Procurement' element={<Procurement/>}></Route>
          <Route path='/Procurement/procurementDetail/:procurement/:id/:type' element={<ProcurementDetail/>}></Route>
          {/* PR */}
          <Route path='/PR' element={<PR/>}></Route>
          <Route path='/PR/Notice' element={<Notice/>}></Route>
          <Route path='/PR/NoticeDetail/:id' element={<NoticeDetail/>}></Route>
          <Route path='/PR/News' element={<News/>}></Route>
          <Route path='/PR/NewsDetail/:id' element={<NewsDetail/>}></Route>
          <Route path='/PR/SNS' element={<SNS/>}></Route>
          {/* Contact */}
          <Route path='/Contact' element={<Contact/>}></Route>
          <Route path='/Contact/Directions' element={<Directions/>}></Route>
        </Route>
        
        {/* 영문버전 */}
        <Route path='/en' element={<EnMould/>}>
          {/* 메인 */}
          <Route path='/en' element={<MainEn/>}></Route>
          
          {/* About */}
          <Route path='/en/AboutUs' element={<AboutUsEn/>}></Route>
          <Route path='/en/AboutUs/outline' element={<OutlineEn/>}></Route>
          <Route path='/en/AboutUs/management' element={<ManagementEn/>}></Route>
          <Route path='/en/AboutUs/cooperation' element={<CooperationEn/>}></Route>
          <Route path='/en/AboutUs/history' element={<HistoryEn/>}></Route>
          <Route path='/en/AboutUs/finance' element={<FinanceEn/>}></Route>
          {/* R&D */}
          <Route path='/en/Research' element={<ResearchEn/>}></Route>
          <Route path='/en/Research/material' element={<MaterialEn/>}></Route>
          <Route path='/en/Research/Exam' element={<ExamEn/>}></Route>
          <Route path='/en/Research/Expandability' element={<ExpandabilityEn/>}></Route>
          <Route path='/en/Research/Patent' element={<PatentEn/>}></Route>
          <Route path='/en/Research/Virus' element={<VirusEn/>}></Route>
          {/* Product */}
          <Route path='/en/Product' element={<ProductEn/>}></Route>
          <Route path='/en/Product/ProductDetail/:id' element={<ProductDetailEn/>}></Route>
          {/* 공공조달 */}
          <Route path='/en/Procurement' element={<ProcurementEn/>}></Route>
          <Route path='/en/Procurement/procurementDetail/:procurement/:id/:type' element={<ProcurementDetailEn/>}></Route>
          {/* PR */}
          <Route path='/en/PR' element={<PREn/>}></Route>
          <Route path='/en/PR/Notice' element={<NoticeEn/>}></Route>
          <Route path='/en/PR/NoticeDetail/:id' element={<NoticeDetailEn/>}></Route>
          <Route path='/en/PR/News' element={<NewsEn/>}></Route>
          <Route path='/en/PR/NewsDetail/:id' element={<NewsDetailEn/>}></Route>
          <Route path='/en/PR/SNS' element={<SNSEn/>}></Route>
          {/* Contact */}
          <Route path='/en/Contact' element={<ContactEn/>}></Route>
          <Route path='/en/Contact/Directions' element={<DirectionsEn/>}></Route>
        </Route>
        {/* PersonalNformation */}
        <Route path='/PersonalNformation' element={<PersonalNformation/>}></Route>
        <Route path='/en/PersonalNformation' element={<PersonalNformationEn/>}></Route>
        {/* 404 */}
        <Route path='*' element={<Main/>}></Route>
      </Routes>
    </div>
  );
}

export default App;