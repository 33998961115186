import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
// import  logo  from '';

function Header() {
    let navigate = useNavigate();
    let [ hdActive, setHdActive ] = useState('');
    let [ scrollPosition, setScrollPosition ] = useState(0);
    let [ siteMap, setSiteMap ] = useState('');
    let [ toggle, setToggle ] = useState({tg1: '', tg2: '', tg3: '', tg4: '', tg5: '', tg6: ''});
    let tgHandler = (event) =>{
        let copy = { ...toggle };
        let activetab = event.currentTarget.id;
        for(let key in copy) {
            if (key === activetab) {
                if (copy[key] == '') {
                    copy[key] = ' open'
                }else{
                    copy[key] = ''
                }
            }else{
                copy[key] = ''
            }
        };
        setToggle(copy);
    }


    let updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }
    useEffect(()=>{
        window.addEventListener('scroll', updateScroll);
        if (scrollPosition == 0) {
            setHdActive("");
        }else{
            setHdActive(' active')
        };
        if (siteMap == ' sitemap') {
            document.querySelector('body').style.overflow = 'hidden';
            document.querySelector('body').style.height= '100%';
        }else{
            document.querySelector('body').style.overflow = 'unset';
            document.querySelector('body').style.height= 'unset';
        };
    });

    return (
        <div className={"header" + hdActive + siteMap + " active"}>
            <h1 className='logo' onClick={() => {navigate("")}}></h1>
            <div className='header-ri'>
                <div className='nav-wrap'>
                    <div className='mb-logo-wrap' onClick={() => {navigate("")}}>
                        <img src={require("../images/LOGO.png")} alt="" />
                    </div>
                    <ul className='hd-nav'>
                        <li>
                            <Link to={'/AboutUs'} >About Us</Link>
                            <div className='mb-handle' id='tg1' onClick={tgHandler}>
                                <p>About Us</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg1}>
                                <li onClick={() => {navigate("/AboutUs"); setSiteMap('')}}>회사소개</li>
                                <li className='sm-li' onClick={() => {navigate("/AboutUs"); setSiteMap('')}}>BIO3S 목표</li>
                                <li className='sm-li' onClick={() => {navigate("/AboutUs/outline"); setSiteMap('')}}>기업개요</li>
                                <li className='sm-li' onClick={() => {navigate("/AboutUs/management"); setSiteMap('')}}>CEO 인사말</li>
                                <li className='sm-li last' onClick={() => {navigate("/AboutUs/Cooperation"); setSiteMap('')}}>회사조직 및 협력기관</li>
                                <li onClick={() => {navigate("/AboutUs/history"); setSiteMap('')}}>발자취</li>
                                <li onClick={() => {navigate("/AboutUs/finance"); setSiteMap('')}}>재무정보</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/Research'} >R&D</Link>
                            <div className='mb-handle' id='tg2' onClick={tgHandler}>
                                <p>R&D</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg2}>
                                <li onClick={() => {navigate("/Research"); setSiteMap('')}}>OVC Technology</li>
                                <li className='sm-li' onClick={() => {navigate("/Research/material"); setSiteMap('')}}>핵심소재</li>
                                <li className='sm-li' onClick={() => {navigate("/Research/Exam"); setSiteMap('')}}>인체임상시험</li>
                                <li className='sm-li last' onClick={() => {navigate("/Research/Expandability"); setSiteMap('')}}>확장성</li>
                                <li onClick={() => {navigate("/Research/Patent"); setSiteMap('')}}>연구성과</li>
                                <li onClick={() => {navigate("/Research/Virus"); setSiteMap('')}}>바이러스 연구관련 지적재산권</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/Product'} >Product</Link>
                            <div className='mb-handle' id='tg3' onClick={tgHandler}>
                                <p>Product</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg3}>
                                <li onClick={() => {navigate("/Product"); setSiteMap('')}}>Clean Tech</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/procurement'} >공공조달</Link>
                            <div className='mb-handle' id='tg6' onClick={tgHandler}>
                                <p>공공조달</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg6}>
                                <li onClick={() => {navigate("/procurement"); setSiteMap('')}}>공공조달</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/PR'} >Media</Link>
                            <div className='mb-handle' id='tg4' onClick={tgHandler}>
                                <p>Media</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg4}>
                                <li onClick={() => {navigate("/PR"); setSiteMap('')}}>ESG 활동</li>
                                <li onClick={() => {navigate("/PR/News"); setSiteMap('')}}>회사소식</li>
                                <li className='sm-li' onClick={() => {navigate("/PR/News"); setSiteMap('')}}>기업뉴스</li>
                                <li className='sm-li last' onClick={() => {navigate("/PR/Notice"); setSiteMap('')}}>자료실</li>
                                <li onClick={() => {navigate("/PR/SNS"); setSiteMap('')}}>SNS</li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/Contact'} >Contact Us</Link>
                            <div className='mb-handle' id='tg5' onClick={tgHandler}>
                                <p>Contact Us</p>
                                <i className='xi-angle-down'></i>
                            </div>
                            <ul className={'sm-nav' + toggle.tg5}>
                                <li onClick={() => {navigate("/Contact"); setSiteMap('')}}>고객문의</li>
                                <li onClick={() => {navigate("/Contact/Directions"); setSiteMap('')}}>오시는 길</li>
                            </ul>
                        </li>
                    </ul>
                    <button className='close-btn' onClick={() => { setSiteMap("") }}>
                        <i className='xi-close'></i>
                    </button>
                </div>
                
                <span className='hd-btn' onClick={() => { navigate("/en") }}><i className='xi-globus'></i></span>
                <span className='hd-btn' onClick={() => { setSiteMap(" sitemap") }}><i className='xi-bars'></i></span>
            </div>
        </div>
    )
}

export default Header;