import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AboutUsMap from '../sub-map/AboutUsMap';

function Management() {


    useEffect(() => {
        const options = {
            root: null, // viewport
            rootMargin: "500px",
            threshold: 1,  // 50%가 viewport에 들어와 있어야 callback 실행
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active');
                } else {
                    entry.target.classList.remove('active');
                }
            });
        }, options);

        const boxList = document.querySelectorAll('.box');
        console.log(boxList);

        boxList.forEach(el => observer.observe(el));
    })


    return (
        <div className='AboutUs introduce management'>
            <AboutUsMap tag2={"회사소개"} tag3={"CEO 인사말"}></AboutUsMap>
            <div className='mb-sub-menu container'>
                <ul>
                    <li><Link to={"/AboutUs"}>BIO3S 목표</Link></li>
                    <li><Link to={"/AboutUs/outline"}>기업개요</Link></li>
                    <li className='now'><Link to={"/AboutUs/management"}>경영진 소개</Link></li>
                    <li><Link to={"/AboutUs/Cooperation"}>회사조직 및 협력기관</Link></li>
                </ul>
            </div>
            <div className='sub-con'>
                <div className='section1'>
                    <div className='content_container container'>
                        <h3 className='h3-st2'>CEO 인사말</h3>
                        <div className='ceo_banner_wrap section1'>
                            <div className='banner_txt_wrap'>
                                <p className='banner_txt'>CEO GREETING</p>
                            </div>
                            <h3 className='banner_tit'>
                                <span className='point_1 point'>감염병 예방</span>의<br className='mbView'/> 새로운 지평을 여는<br />
                                <span className='point_2 point'>노로-X 손세정제와</span><br className='mbView'/> 함께하는 우리의 약속
                            </h3>
                            <img className='banner_img pcView' src={require("../../images/ceo_banner_img.webp")} />
                            <img className='banner_img mbView' src={require("../../images/mb-img/ceo_banner_img_m.webp")} />
                            <img className='ceo_photo' src={require("../../images/ceo_photo.webp")} alt="" />
                        </div>
                        <div className='main_txt_wrap'>
                            <h4 className='main_tit'>안녕하십니까, <br className='mbView'/>바이러스 감염병 예방의 선두주자, <br className='mbView'/>㈜바이오쓰리에스의 대표 김두운입니다. </h4>
                            <p className='main_txt'>
                                우리의 일상과 건강을 위협하는 바이러스로부터 보호하기 위한 저희의 끊임없는 연구와 혁신에 대해 소개드리고자 합니다.
                            </p>
                            <p className='main_txt'>
                                COVID-19 팬데믹이 우리에게 가르친 교훈 중 하나는 바이러스 예방의 절대적 중요성입니다.<br className='pcView'/>
                                이 시기를 거치며 자연에서 찾은 해답, 작두콩에서 추출한 소재로 '노로-X 손세정제'를 개발하였습니다.<br className='pcView'/>
                                이 제품은 조달청으로부터 우수제품으로 인정받아, 식약처, 세종정부청사, 학교, 우체국 등 여러 공공기관에 필수품으로 자리잡았습니다.
                            </p>
                            <p className='main_txt'>
                                2023년, 저희 바이오쓰리에스는 지역사회공헌기업으로의 지정을 받았습니다. 이는 저희의 ESG 경영 실현 노력이 인정받은 결과이며,<br className='pcView'/>
                                저희는 이를 통해 공공기관 현장과 사회적 약자 계층에서 감염병 예방을 위한 저희의 물적, 지적 자원을 아낌없이 공유하고자 합니다.
                            </p>
                            <p className='main_txt'>
                                바이오쓰리에스는 단순히 제품을 판매하는 것을 넘어, 공공기관에서 감염병 제로 실현을 위한 예방교육 제공과 공공현장의 애로사항 해결에 앞장서고자 합니다.<br className='pcView'/>
                                신기술, 신제품, 우수제품, 혁신제품 인증을 받는데 6년이라는 연구 및 개발 시간이 소요되었지만, 이제 저희는 감염병 없는 공공기관 업무환경 조성을 위해<br className='pcView'/>
                                더욱 힘쓸 준비가 되어 있습니다.
                            </p>
                            <p className='main_txt'>
                                저희가 축적한 바이러스 감염병 예방교육 내용을 바탕으로, 귀 기관에서 감염병 예방 교육이 필요하시다면 재능기부 형태로 지원하겠습니다.<br className='pcView'/>
                                감염병 예방에 대한 교육이나 상담이 필요하시다면, 언제든지 저희에게 연락 주십시오.
                            </p>
                            <p className='main_txt'>
                                건강한 사회, 안전한 일상을 위해 바이오쓰리에스가 여러분과 함께하겠습니다.<br className='pcView'/>
                                여러분의 관심과 지원에 진심으로 감사드립니다.
                            </p>
                            <p className='main_txt'>
                                감사합니다.
                            </p>
                            <p className='main_txt_bottom main_txt'>
                                바이오쓰리에스 대표<br />
                                <strong>김두운 배상</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Management;